// import './style.css';
import {Spin, Row, Space, Modal, Card} from 'antd';
import React, {useState, useContext, useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {TrackingNoDashboardSearchFrom} from '../../constants/global/SearchForm';
import {PageLayout} from '../../components/pageLayout/PageLayout';
import {TrackingNoDashboardColumn} from '../../constants/global/columnTableForm';
import {ButtonTheme} from '../../components/buttons';
import {MailOutlined} from '@material-ui/icons';
import TrackingNoContext from './TrackingNoProvider';
import TrackingNoModal from '../../components/modal/TrackingNo/DisplayTrackingNoDetails';
import {Overview} from './styled-component';
import AddTrackingTaskModal from '../../components/modal/TrackingNo/AddTrackingTaskModal';
import Wrapper from '../../views/wrapper/Wrapper';
import {color} from '../../resources/color';
import ImportExcelFileTrackingNo from '../../components/modal/TrackingNo/ImportExcel';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import {useGetTerminationOfContracts} from './api';
import {GET, GET_JOBTYPE, GET_TRACKERS} from '../../services';

const TrackingnoInfo = () => {
  const history = useHistory();

  const ctx = useContext(TrackingNoContext);

  const [dataPass, setDataPass] = useState();
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [visibleAddTask, setVisibleAddTask] = useState(false);
  const [visibleImport, setVisibleImport] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [filterData, setfilterData] = useState({}); 
  const [resultReport, loadingReport, errorReport, setInputReport] = useGetTerminationOfContracts();

  useEffect(() => {
    if (resultReport?.message === 'success' && resultReport?.url) {
      setTimeout(() => {
        window.open(resultReport?.url, '_blank', 'noopener,noreferrer');
        closeModal('report');
      }, 1000);
    }
  }, [resultReport]);

  useEffect(() => {
    if (errorReport) {
      const message = errorReport?.response?.data?.errors;

      Modal.error({title: 'ดึงข้อมูลไม่สำเร็จ', content: message});
      closeModal('report');
    }
  }, [errorReport]);

  const handleSearch = (data) => {
    setfilterData(data); // บันทึกข้อมูลฟิลเตอร์ใน state
    ctx.getFilteredData(data); // เรียกใช้งานฟังก์ชันการค้นหาจริง
  };

  const onExportExcelReport = async () => ctx?.paramsUrl !== null && setInputReport(ctx?.paramsUrl);
  const [optionTracker, setOptionTracker] = useState([]);
  const [jobTypeOption, setJobTypeOption] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const res = await GET(GET_TRACKERS);
      const {success, result} = res;
      if (success) {
        const option = result.map((item) => ({
          label: item.name,
          value: item.trackerID,
        }));
        setOptionTracker(option);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  }, []);

  const fetchJobType = useCallback(async () => {
    try {
      const res = await GET(GET_JOBTYPE);
      const {success, result} = res;
      if (success) {
        const option = result.map((item) => ({
          label: item.label,
          value: item.value,
        }));
        setJobTypeOption(option);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  }, []);

  useEffect(() => {
    fetchData();
    fetchJobType();
  }, []);

  const openModal = (ref, record) => {
    switch (ref) {
      case 'details':
        setVisibleDetails(true);
        break;
      case 'addTask':
        setVisibleAddTask(true);
        break;
      case 'report':
        setVisibleModal(true);
        break;
      default:
        break;
    }

    ref !== 'report' && setDataPass(record);
  };

  const closeModal = (ref) => {
    switch (ref) {
      case 'details':
        setVisibleDetails(false);
        break;
      case 'addTask':
        setVisibleAddTask(false);
        break;
      case 'import':
        setVisibleImport(false);
        break;
      case 'report':
        setVisibleModal(false);
        break;
      default:
        break;
    }

    ref !== 'report' && ctx.getFilteredData(filterData);
  };

  const addTrackingNo = () => history.push({pathname: '/add_trackingno'});

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={ctx.isLoading || loadingReport}>
          <PageLayout
            searchLayout={{
              title: 'จ่ายงานภาคสนาม',
              icon: <MailOutlined />,
              disabledSearchButton: ctx.isLoading,
              spanSearch: 24,
              formSearch: TrackingNoDashboardSearchFrom({
                option: {
                  trackingOptions: ctx.trackingOptions,
                  contractOptions: ctx.contractOptions,
                  sendingOptions: ctx.sendingOptions,
                  contractNoOptions: ctx.optionContractNo,
                  customerNameOptions: ctx.optionCustomerName,
                },
              }),

              onSearch: (data) => handleSearch(data),
            }}
            tableLayout={{
              columns: TrackingNoDashboardColumn({openModal}),
              dataSource: ctx.dataSource,
              hidden: ctx.hideTable,
              extraContent: (
                <Row style={{width: '100%'}} align="bottom" justify="space-between">
                  <Space>
                    {ctx.summary &&
                      ctx.summary.map((item, index) => {
                        return <Overview key={index} color={item.color}>{`${item.label}${item.value}`}</Overview>;
                      })}
                  </Space>
                  <Space>
                    <ButtonTheme
                      useFor="CUSTOM"
                      style={{color: 'white'}}
                      bgcolor={color.manage}
                      title={'Import Excel เลขพัสดุ'}
                      onClick={() => setVisibleImport(true)}
                    />
                    <ButtonTheme useFor="CREATE" title={'เพิ่ม Tracking No.'} onClick={() => addTrackingNo()} />
                    <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => openModal('report')} />
                  </Space>
                </Row>
              ),
            }}
          />
        </Spin>
      </Card>

      {visibleDetails && <TrackingNoModal visible={visibleDetails} onClose={() => closeModal('details')} data={dataPass}></TrackingNoModal>}

      <ImportExcelFileTrackingNo visible={visibleImport} onClose={() => closeModal('import')} />

      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={() => closeModal('report')}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={async () => await onExportExcelReport()} />
          <ButtonTheme useFor="CANCEL" onClick={() => closeModal('report')} />
        </ContainerButton>
      </Modal>
      {visibleAddTask && (
        <AddTrackingTaskModal
          visible={visibleAddTask}
          onClose={() => closeModal('addTask')}
          data={dataPass}
          options={{optionTracker, jobTypeOption}}></AddTrackingTaskModal>
      )}
      <ImportExcelFileTrackingNo visible={visibleImport} onClose={() => closeModal('import')} />
    </Wrapper>
  );
};

export default TrackingnoInfo;
