import {useState} from 'react';
import axios from 'axios';
import {Modal} from 'antd';
import {API_URL} from '../../../../../env.js';

export const useEditGuest = () => {
  const [loading, setLoading] = useState(false);

  const editGuest = async (input) => {
    try {
      if (input?.id) {
        setLoading(true);

        const response = await axios.post(`${API_URL}/admin/customer/edit-guest`, input, {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
            'content-type': 'application/json',
          },
        });

        if (response?.data) return true;
        throw new Error();
      }
    } catch (err) {
      Modal.error({
        title: 'บันทึกข้อมูลไม่สำเร็จ',
        content: err?.response?.data?.error,
      });
    } finally {
      setLoading(false);
    }
  };

  return [editGuest, loading];
};
