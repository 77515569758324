export const EditRemark1Column = () => {
  return [
    {
      title: 'วันที่บันทึก',
      dataIndex: 'date_create',
      key: 'date_create',
      width: '15%',
      render: (item) => {
        return item;
      },
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '20%',
    },
    {
      title: 'บันทึกโดย',
      dataIndex: 'user_create',
      key: 'user_create',
      width: '25%',
    },
  ];
};
