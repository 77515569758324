import React, {useEffect, useState} from 'react';
import {Card, Spin, Row, Col, Modal} from 'antd';
import Wrapper from '../../views/wrapper/Wrapper';
import {TableLayout} from '../../components/pageLayout/TableLayout';
import {ContainerButton} from '../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../components/buttons';
import {AdjustStockColumn} from '../../constants/global/columnTableForm';
import {useAuthState} from '../../utils/hook';
import {auth} from '../../utils/firebase';
import {EditableAdjustCell} from './form/editCellAdjustStock';
import moment from 'moment';
import {GET, GET_SERIAL_BY_ITEM_CODE, GET_NO_ADJUST, INSERT_ADJUST, POST, GET_BRANCH, GET_PRODUCTS_STOCK, GET_PRODUCTS} from '../../services/index';
import {ExclamationCircleOutlined, AuditOutlined} from '@ant-design/icons';

const AdjustStock = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [docNoAdjust, setDocNoAdjust] = useState('');
  const [optionItems, setOptionItems] = useState([]);
  const [optionBranch, setOptionBranch] = useState([]);
  const {user} = useAuthState(auth);

  useEffect(() => {
    getNoAdjust();
    getItemsStock();
    getOptionBranch();
  }, []);

  const optionSerialStatus = [
    {value: 'READY', label: 'พร้อมขาย'},
    {value: 'NOT_READY', label: 'ไม่พร้อมขาย'},
  ];

  const getItemsStock = async () => {
    try {
      const res = await GET(GET_PRODUCTS);
      const {success, result} = res;
      if (success) {
        setOptionItems(result.products);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const getOptionBranch = async () => {
    try {
      const res = await GET(GET_BRANCH);
      const {success, result} = res;
      if (success) {
        const option = result.branch.map((el) => {
          return {
            label: el.label,
            value: el.value,
          };
        });
        setOptionBranch(option);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  };

  const getNoAdjust = async () => {
    try {
      const res = await GET(GET_NO_ADJUST);
      const {success, result} = res;
      if (success) {
        setDocNoAdjust(result.document_no);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const handleAdd = () => {
    const newData = {
      key: dataSource.length + 1,
      type: null,
      item_code: null,
      cost: 0,
      serial_number: null,
      serial_number2: null,
      serial_option: [],
      serial_status: null,
      serial_status_option: [],
      dataSerial: [],
      branch: null,
      branch_name: '',
      item_name: '',
    };
    setDataSource([...dataSource, newData]);
  };

  const onRemove = (key) => {
    const data = dataSource.filter((i) => i.key !== key);
    setDataSource(data);
  };

  const onChange = (value, ref, key) => {
    dataSource.forEach((el) => {
      if (el.key === key) {
        if (ref === 'item_code') {
          if (!value) {
            el.dataSerial = [];
            el.serial_option = [];
            el.serial_status_option = [];
          }
          el.serial_number = null;
          el.serial_number2 = null;
          el.serial_status = null;
          el.cost = '';
          el.branch = '';
          el.branch_name = '';

          el.item_code = value || null;
          const dataFilter = optionItems.filter((i) => i.value === value);
          el.item_name = dataFilter.length > 0 ? dataFilter[0].label : '';
          getSerialByItemCode(value, key);
        } else if (ref === 'serial_number') {
          if (el.type === 'NEG') {
            const dataFilter = el.dataSerial.filter((i) => i.serial_number === value);
            el.serial_number2 = dataFilter[0].serial_number2;
            el.cost = dataFilter[0].cost;
            el.branch = dataFilter[0].branch;
            el.branch_name = dataFilter[0].branch_name;
            el.serial_status = dataFilter[0].serial_status;
          }

          el.serial_number = value;
        } else if (ref === 'type') {
          if (!value) {
            el.serial_number = null;
            el.dataSerial = [];
            el.serial_option = [];
            el.cost = '';
            el.branch = '';
            el.branch_name = '';
            el.item_code = null;
            el.item_name = '';
            el.serial_status = null;
            el.serial_status_option = [];
          }
          el.type = value;
        } else if (ref === 'branch') {
          el.branch_name = optionBranch.filter((el) => el.value === value)[0].label || '';
          el.branch = value;
        } else if (ref === 'serial_status') {
          el.serial_status = value;
        } else {
          el[ref] = value;
        }
      }
    });

    const newData = [...dataSource];
    setDataSource(newData);
  };

  const components = {
    body: {
      cell: EditableAdjustCell,
    },
  };

  const getSerialByItemCode = async (code, key) => {
    if (code) {
      try {
        const res = await GET(GET_SERIAL_BY_ITEM_CODE(code));
        const {success, result} = res;
        if (success) {
          const option = result.items.map((el) => {
            return {
              value: el.serial_number,
              value2: el.serial_number2,
              label: el.serial_number,
            };
          });

          dataSource.forEach((val) => {
            if (val.key === key) {
              val.serial_option = option || [];
              val.dataSerial = result.items;
            }
          });

          setDataSource([...dataSource]);
        }
      } catch (err) {
        const {message, error} = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      }
    }
  };

  const handleInsertAdjust = async () => {
    // let serial = []
    // for( let i=0; i<dataSource.length; i++ ) {
    //   if(serial.includes(dataSource[i].serial_number)){
    //     Modal.error({
    //         title:"มี SN ซ้ำ กรุณาตรวจสอบข้อมูลให้ถูกต้อง"
    //     })
    //   }else{
    //     serial.push(dataSource[i].serial_number)
    //   }
    // }

    const dataItems = dataSource.map((vl) => {
      const {item_code, branch, branch_name, cost, item_name, serial_number, serial_number2, type, serial_status} = vl;
      return {branch, branch_name, cost: +cost, item_code, item_name, serial_number, serial_number2, type, serial_status};
    });

    // const dataList = handleSerialStatus(dataItems);

    const datasSend = {
      userLogin: user.email,
      documentNo: docNoAdjust,
      items: dataItems,
    };

    setLoading(true);
    try {
      const res = await POST(INSERT_ADJUST, datasSend);
      const {success, message} = res;
      if (success) {
        Modal.success({
          title: message,
        });
        setDataSource([]);
        getNoAdjust();
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSerialStatus = (data) => {
    // Collect serial_status of elements with even index and type 'NEG'
    let statusToCopy = [];
    data.forEach((item, index) => {
      if (index % 2 === 0 && item.type === 'NEG') {
        statusToCopy.push(item.serial_status);
      }
    });

    // Update the serial_status of elements with odd index and type 'POS'
    const updatedData = data.map((item, index) => {
      if (index % 2 === 1 && item.type === 'POS') {
        // Use the corresponding status from the even index
        const correspondingStatus = statusToCopy[Math.floor(index / 2)];
        return {...item, serial_status: correspondingStatus};
      }
      return item;
    });

    return updatedData;
  };

  const onConfirm = () => {
    Modal.confirm({
      title: 'ยืนยันปรับปรุงสต็อก',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการการปรับปรุงสต็อก ใช่หรือไม่',
      onOk() {
        handleInsertAdjust();
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <TableLayout
            props={{
              title: 'ปรับปรุงสต็อก',
              icon: <AuditOutlined />,
              columns: AdjustStockColumn({onChange, onRemove, options: {optionItems, optionBranch, optionSerialStatus}}),
              dataSource: dataSource,
              components: components,
              extraContent: (
                <>
                  <Row gutter={[16, 16]} justify="right" style={{marginBottom: '20px'}}>
                    <Col xs={{span: 24}} md={{span: 24}} xl={{span: 12}} lg={{span: 12}}>
                      {`วันที่ทำรายการ ${moment().format('DD-MM-YYYY')}`}
                    </Col>
                    <Col xs={{span: 24}} md={{span: 24}} xl={{span: 12}} lg={{span: 12}}>
                      {`เลขที่เอกสาร : ${docNoAdjust}`}
                    </Col>
                  </Row>
                  <ContainerButton left>
                    <ButtonTheme useFor="CREATE" title={'เพิ่มสินค้า'} onClick={handleAdd} />
                  </ContainerButton>
                </>
              ),
              footerContent:
                dataSource.length > 0 ? (
                  <ContainerButton center>
                    <ButtonTheme useFor="SUBMIT" title={'บันทึก'} onClick={onConfirm} />
                  </ContainerButton>
                ) : (
                  ''
                ),
            }}
          />
        </Spin>
      </Card>
    </Wrapper>
  );
};

export default AdjustStock;
