import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { useForm } from 'react-hook-form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import RenderForm from '../../../constants/global/RenderForm';
import { CANCEL_LOOKUP, GET_APH_CONFIRM_SECOND_HAND, GET_APH_LOOKUP_SECOND_HAND, POST } from '../../../services/index';
import { convertStrToFormat } from '../../../functions/fn';
import { useAuthState } from '../../../utils/hook';
import { auth } from '../../../utils/firebase';
import { SecondHandVerifyForm } from '../form';

export const SecondHandVerifyModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const { user } = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState({});
  const [statusReject, setStatusReject] = useState(false);

  useEffect(async () => {
    if (JSON.stringify(data && data.record) !== "{}" && data.record) {
      const dataValue = Object.keys(data.record);
      dataValue.forEach((val) => setValue(val, data.record[val]));
      setDataSource(data.record);
    }
  }, [data.record]);

  const onSubmit = async (params) => {
    setLoading(true);

    try {

      let res = await POST(GET_APH_LOOKUP_SECOND_HAND, { document_no: dataSource?.document_no }); // เปลี่ยนเป็นส่ง params ให้ API ของพี่พจน์ตามที่พี่เขาต้องการ
      const { success, message, data } = res;

      if (success) {
        let content = (
          <p>
            {`ชื่อบัญชี: ${data?.result.receivingAccountName}`}
            <br />
            {`เลขที่บัญชี: ${data?.result.receivingAccountNumber}`}
            <br />
            {`จำนวนเงิน: ${convertStrToFormat(data.result.lease_amount, 'money')}`}
            <br />
            <span style={{ color: '#dc3545' }}>{`หลังจากกดยืนยันแล้วจะทำการโอนเงินทันที`}</span>
          </p>
        );

        Modal.confirm({
          title: 'ยืนยันการโอนเงิน',
          icon: <ExclamationCircleOutlined />,
          content: content,
          onOk() {
            onConfirmSecondHand(params, data?.result?.txnReferenceNo);
          },
          okText: 'ยืนยัน',
          onCancel() {
            onCancelLookUp(data.result?.txnReferenceNo);
          },
          cancelText: 'ยกเลิก'
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }

  };

  const onConfirmSecondHand = async (params, ref) => {
    setLoading(true);
    try {
      const { document_no, remark } = params;

      const obj = {
        document_no: document_no,
        statusApprove: statusReject ? 'N' : 'Y',
        remark: remark,
        userLogin: user.email,
        txnReferenceNo: ref,
      };

      let res = await POST(GET_APH_CONFIRM_SECOND_HAND, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: statusReject ? 'ไม่อนุมัติรายการสำเร็จ' : message,
          afterClose: () => {
            onCloseMain();
            data.reApi({});
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onCancelLookUp = async (ref) => {
    setLoading(true);
    try {
      const obj = {
        txnReferenceNo: ref,
      };
      let res = await POST(CANCEL_LOOKUP, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            data.reApi({});
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message || err || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onConfirm = (params) => {
    if (statusReject) {
      onConfirmSecondHand(params, '');
    } else {
      onSubmit(params);
    }
  };

  const onCloseMain = () => {
    setStatusReject(false);
    clearErrors();
    setValue('remark', '')
    onClose();
  };

  return (
    <>
      <Modal title={title || 'modal'} open={visible} width={800} onCancel={onCloseMain} footer={null} maskClosable={false}>
        <div>
          <Row style={{ marginBottom: '8px', marginTop: '8px' }}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }}>
              <div>
                <form onSubmit={handleSubmit(onConfirm)}>
                  <RenderForm
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    formList={SecondHandVerifyForm({ data: dataSource })}
                    setValue={setValue}
                    getValues={getValues}
                    renderButton={
                      <>
                        <ContainerButton right>
                          <ButtonTheme useFor="CONFIRM" title="อนุมัติจ่ายเงิน" htmlType="submit" loading={loading}/>
                          <ButtonTheme useFor="REJECT" title="ไม่อนุมัติ" htmlType="submit" onClick={() => setStatusReject(true)} />
                        </ContainerButton>
                      </>
                    }
                  />
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

