import React, {useState, useEffect} from 'react';
import {Modal, Tag, Row, Col, Image, Input, Button, Typography, Card, message} from 'antd';
import { CopyOutlined, EditOutlined } from '@ant-design/icons';
import {color} from '../../../resources/color';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {GET, POST, PUT, POST_APH_PDF_EXPORT_SECOND_HAND, PUT_APH_APPROVE_DOC, EDIT_APH_REMARK_SECOND_HAND, GENERATE_URL_SIGN_SECON_HAND, VALIDATE_URL_SIGN_SECON_HAND } from '../../../services/index';
import {saveAs} from 'file-saver';
import {TextSmall} from '../../../components/forms/text';
import { Icon } from '../../../resources/icon';
import { SecondHandDetailForm, SecondHandDetailForm2 } from '../form';

const {TextArea} = Input;
const {Text} = Typography;

export const DetailModal = ({title, visible, onClose, data}) => {
  const [value, setValue] = useState({});
  const fields = SecondHandDetailForm();
  const fieldRemark = SecondHandDetailForm2();
  const menu_user = JSON.parse(localStorage.getItem('menu_user'));
  const permissions = menu_user.length > 0 && menu_user.filter((el) => el.head === 'Ranraroeng')[0].menu;
  const admin_branch = localStorage.getItem('admin_branch');

  const perAction = permissions.length > 0 && permissions.filter((el) => el.path === '/aph-buy-second-hand-report')[0].actions;
  const [enabledEditRemark, setEnabledEditRemark] = useState(false);
  const [remark, setRemark] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [visibleDetailImage, setVisibleDetailImage] = useState(false);
  const [detailImage, setDetailImage] = useState([]);
  const [visibleMobileImage, setVisibleMobileImage] = useState(false);
  const [mobileImage, setMobileImage] = useState([]);
  const [visibleDocumentImage, setVisibleDocumentImage] = useState(false);
  const [documentImage, setDocumentImage] = useState([]);

  useEffect(async () => {
    if (JSON.stringify(data && data.record) !== '{}' && data.record) {
      const dataValue = Object.keys(data.record);
      const newValue = {};
      dataValue.forEach((val) => {
        newValue[val] = data.record[val];
      });

      let detailImg = []

      if (data.record['signature'] === '') {
        detailImg = [data.record['profile_image'], data.record['book_bank_image']]
      } else {
        detailImg = [data.record['profile_image'], data.record['book_bank_image'], data.record['signature']];
      }
      const mobileImg = data.record.imageMobile
      const documentImg = data.record.imageDocument

      setValue(newValue);
      setRemark(data.record['remark']);
      setDetailImage(detailImg || []);
      setMobileImage(mobileImg || []);
      setDocumentImage(documentImg || [])
    }
  }, [data.record]);

  const onCloseMain = () => {
    onClose();
  };

  const downloadContract = async (value) => {
    try {
      let result = data.record['pdf_url'] ? data.record['pdf_url'] : value;
      if (result === value || isExpiredLink(result)) {
        const res = await POST(POST_APH_PDF_EXPORT_SECOND_HAND(value));
        if (res) {
          saveAs(res.data.file, `สัญญาซื้อขายเลขที่ ${value}.pdf`);
        }
      } else {
        saveAs(result, `สัญญาซื้อขายเลขที่ ${value}.pdf`);
      }

      function isExpiredLink(url) {
        // แยก query parameters จาก URL
        const urlObject = new URL(url);
        let result = false;
        // ตรวจสอบว่ามีคิวรี X-Goog-Expires ใน URL หรือไม่
        const expiresInSec = urlObject.searchParams.get('X-Goog-Expires');
        if (expiresInSec) {
          // หากมีคิวรี X-Goog-Expires ใน URL ให้ถือว่าหมดอายุ
          result = true;
        }
        return result;
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: 'ไม่สามารถสร้างสัญญาได้',
        content: error || 'เพราะไม่มีข้อมูลสินค้าในระบบที่ตรงกัน',
      });
    }
  };

  const tagStatus =
    value['status'] === 'SUCCESS'
      ? color.success
      : value['status'] === 'WAIT_FINANCE_APPROVE'
      ? color.tranfer
      : value['status'] === 'WAIT_ADMIN_APPROVE'
      ? color.tracking
      : value['status'] === 'WAIT_SIGNATURE'
      ? color.edit
      : color.disabled;

  const approveDoc = async (text) => {
    if (data.record['status'] !== 'WAIT_ADMIN_APPROVE') return;
    const value = {
      id: data.record['key'],
      status: 'WAIT_ADMIN_APPROVE',
      button: text,
    };
    let params = [];
    let result = '';

    Object.keys(value).forEach((key) => {
      if (value[key]) {
        params.push(key + '=' + value[key]);
      }
    });

    if (params.length !== 0) {
      result += params.join('&');
    }

    if (!result) return;

    try {
      const res = await PUT(PUT_APH_APPROVE_DOC(result));
      const {success, message} = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            data.reApi(data.valueSearch);
            onCloseMain();
          },
        });
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const handleChange = (e) => {
    setRemark(e.target.value);
  };

  const onEditRemark = async () => {
    // setLoading(true);

    try {
      let res = await PUT(EDIT_APH_REMARK_SECOND_HAND(value.key), {remark: remark});
      const {success} = res;
      if (success) {
        data.reApi(data.valueSearch);
        data.reApi2(value.document_no);
        setEnabledEditRemark(false);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      // setLoading(false);
    }
  };

  const showTerminateDate = (value) => {
    switch (value) {
      case 'WAIT_ADMIN_APPROVE':
      case 'WAIT_FINANCE_APPROVE':
      case 'SUCCESS':
        return false;
      case 'TERMINATE':
        return true;
      default:
        return true;
    }
  };

  const validateLink = async () => {
    try {
      const docNo = value.document_no
      const { success, result } = await GET(VALIDATE_URL_SIGN_SECON_HAND(docNo));

      if(success && result?.isActive === true){

        Modal.confirm({
          title: "ยืนยัน",
          icon: <Icon.confirm />,
          content: "ลิงค์เดิมยังไม่หมดอายุ คุณต้องการสร้างลิงค์ใหม่ ใช่หรือไม่",
          onOk() {
            generateLink(docNo)
          },
          okText: "ยืนยัน",
          cancelText: "ยกเลิก",
        });
      } else {
        generateLink(docNo)
      }

    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const generateLink = async (docNo) => {
    try {
      // const docNo = value.document_no

      const res = await POST(GENERATE_URL_SIGN_SECON_HAND(docNo));
      const { success, result } = res;

      if (success) {
        setValue({...value, url_sign: result?.url_sign})
        message.success('ลิงค์เซ็นสัญญาออนไลน์ถูกสร้างแล้ว!');
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    }
  };

  const copyToClipboard = () => {

    const link = value?.url_sign

    if (link) {
      navigator.clipboard.writeText(link);
      message.success('คัดลอกลิงค์สำเร็จ!');
    }
  };

  return (
    <>
      <Modal title={title || 'modal'} open={visible} width={1000} onCancel={onCloseMain} footer={null} maskClosable={false}>
        <div>
          <Row style={{marginBottom: '22px', marginTop: '22px'}}>
            <Col xs={{span: 24}} md={{span: 24}} xl={{span: 12}} lg={{span: 12}} style={{textAlign: 'left'}}>
              <Text strong>รูปคู่บัตรประชาชน/รูปหน้าบัญชีธนาคาร/รูปลายมือชื่อ</Text>
              <p></p>
              <div style={{textAlign: 'center', position: 'relative'}}>
                <Image
                  width={'auto'}
                  height={150}
                  src={detailImage[0]}
                  preview={{
                    visible: false,
                  }}
                  onClick={() => setVisibleDetailImage(true)}
                />
                <div style={{display: 'none'}}>
                  <Image.PreviewGroup
                    preview={{
                      visible: visibleDetailImage,
                      onVisibleChange: (vis) => setVisibleDetailImage(vis),
                    }}>
                    {detailImage.map((el, i) => {
                      return <Image height={100} src={el} key={i} />;
                    })}
                  </Image.PreviewGroup>
                </div>
                {detailImage.length > 1 && (
                  <div style={{position: 'absolute', top: '20%', left: '44%'}}>
                    <Text style={{fontSize: 50, textShadow: '#000000 10px 0px 20px', color: 'white'}}>+{detailImage.length}</Text>
                  </div>
                )}
              </div>
              <p></p>
              <Text strong>รูปเครื่อง</Text>
              <p></p>
              <div style={{textAlign: 'center', position: 'relative'}}>
                <Image
                  width={'auto'}
                  height={150}
                  src={(mobileImage && mobileImage[0]) || []}
                  preview={{
                    visible: false,
                  }}
                  onClick={() => setVisibleMobileImage(true)}
                />
                <div style={{display: 'none'}}>
                  <Image.PreviewGroup
                    preview={{
                      visible: visibleMobileImage,
                      onVisibleChange: (vis) => setVisibleMobileImage(vis),
                    }}>
                    {mobileImage.map((el, i) => {
                      return <Image height={100} src={el} key={i} />;
                    })}
                  </Image.PreviewGroup>
                </div>
                {mobileImage.length > 1 && (
                  <div style={{position: 'absolute', top: '20%', left: '44%'}}>
                    <Text style={{fontSize: 50, textShadow: '#000000 10px 0px 20px', color: 'white'}}>+{mobileImage.length}</Text>
                  </div>
                )}
              </div>
              <p></p>
              <Text strong>รูปเอกสาร</Text>
              <p></p>
              <div style={{textAlign: 'center', position: 'relative'}}>
                <Image
                  width={'auto'}
                  height={150}
                  src={(documentImage && documentImage[0]) || []}
                  preview={{
                    visible: false,
                  }}
                  onClick={() => setVisibleDocumentImage(true)}
                />
                <div style={{display: 'none'}}>
                  <Image.PreviewGroup
                    preview={{
                      visible: visibleDocumentImage,
                      onVisibleChange: (vis) => setVisibleDocumentImage(vis),
                    }}>
                    {documentImage.map((el, i) => {
                      return <Image height={100} src={el} key={i} />;
                    })}
                  </Image.PreviewGroup>
                </div>
                {documentImage.length > 1 && (
                  <div style={{position: 'absolute', top: '20%', left: '44%'}}>
                    <Text style={{fontSize: 50, textShadow: '#000000 10px 0px 20px', color: 'white'}}>+{documentImage.length}</Text>
                  </div>
                )}
              </div>
            </Col>
            <Col xs={{span: 24}} md={{span: 24}} xl={{span: 12}} lg={{span: 12}}>
              <div>
                {value['status'] !== 'WAIT_SIGNATURE' ? (
                  <ContainerButton center>
                    <ButtonTheme
                      useFor="CUSTOM"
                      title="ดาวน์โหลดสัญญาซื้อขาย"
                      bgcolor={color.clear}
                      className="export"
                      onClick={() => downloadContract(data.record.document_no)}
                      style={{borderRadius: '5px'}}
                    />
                  </ContainerButton>
                ) : (
                  <></>
                )}
              </div>
              <p></p>
              <div style={{marginLeft: '20px'}}>
                {fields.map((field) => (
                  <div key={field.key} style={{marginBottom: '10px', width: 'auto'}}>
                    <Row>

                      { field.name === 'terminate_date' && !showTerminateDate(value['status']) ? (
                        <></>
                      ) : (field.name === 'url_sign') ? (
                        <Col span={24} style={{fontWeight: 'bold', marginRight: '10px'}}>
                          <span style={{marginRight: 8 }}>{value['url_sign'] && field.label}</span>

                          { value['status'] === 'WAIT_SIGNATURE' && (
                            <Button type="primary" onClick={validateLink}>Generate Link</Button>
                          )}

                          { value['url_sign'] && (
                            <Button style={{ marginLeft:8 }} icon={<CopyOutlined />} onClick={copyToClipboard} />
                          )}
                        </Col>) : (
                        <Col span={6} style={{fontWeight: 'bold', marginRight: '10px'}}>
                          {field.label}
                        </Col>
                      )}

                      {field.name === 'status' ? (
                        <Col span={6}>
                          <Tag color={tagStatus} style={{ width: '120px', textAlign: 'center' }}>
                            {value[field.name] === 'WAIT_ADMIN_APPROVE'
                              ? 'รอเจ้าหน้าที่อนุมัติ'
                              : value[field.name] === 'WAIT_FINANCE_APPROVE'
                              ? 'รอการเงินอนุมัติ'
                              : value[field.name] === 'WAIT_SIGNATURE'
                              ? 'รอเซ็นสัญญา'
                              : value[field.name] === 'SUCCESS'
                              ? 'จ่ายเงินสำเร็จ'
                              : value[field.name] === 'TERMINATE'
                              ? 'ยกเลิก'
                              : value[field.name]}
                          </Tag>
                        </Col>
                      ) : field.name === 'approve_date' ? (
                        value['status'] === 'WAIT_ADMIN_APPROVE' || value['status'] === 'TERMINATE' ? (
                          <Col span={6}>{'-'}</Col>
                        ) : (
                          <Col span={6}>{value[field.name]}</Col>
                        )
                      ) : field.name === 'terminate_date' ? (
                        showTerminateDate(value['status']) ? (
                          <Col span={6}>{value[field.name]}</Col>
                        ) : (
                          <Col span={6}></Col>
                        )
                      ) : field.name === 'url_sign' ? (
                          <Col span={24}>
                            { value['url_sign']}
                          </Col>
                      ) : (
                        <Col span={12}>{value[field.name]}</Col>
                      )}
                    </Row>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
          <Row style={{justifyContent: 'center'}}>
            <Col>
              {fieldRemark.map((field) => (
                <div key={field.key} style={{marginBottom: '10px', width: 'auto', marginInline: '20px'}}>
                  <Row>
                    <div style={{fontWeight: 'bold', marginRight: '10px', marginBottom: '10px'}}>{field.label}</div>
                    {field.name === 'remark' ? (
                      <div style={{width: '100%'}}>
                        {enabledEditRemark ? (
                          <Row style={{justifyContent: 'center'}}>
                            <TextArea style={{width: 800}} maxLength={1500} rows={10} value={remark} onChange={(e) => handleChange(e)} />
                            { (permissions.includes('edit') || value.branchCode === admin_branch ) &&
                              <div style={{display: 'inline-block', marginTop: '10px'}}>
                                <Button
                                  type="ghost"
                                  style={{marginRight: '10px'}}
                                  // style={{ backgroundColor: color.danger, color: color.white, borderRadius: 60 }}
                                  title="Cancel"
                                  onClick={() => setEnabledEditRemark(false)}>
                                  Cancel
                                </Button>
                                <Button
                                  type="primary"
                                  // style={{ backgroundColor: color.submit, color: color.white, borderRadius: 60, marginLeft: 10 }}
                                  title="OK"
                                  onClick={() => onEditRemark()}>
                                  OK
                                </Button>
                              </div>
                            }
                          </Row>
                        ) : (
                          <Card style={{borderRadius: '15px', marginInline: '50px'}}>
                            <TextSmall
                              cursor={'pointer'}
                              style={{display: 'inline-block', width: '100%'}}
                              onClick={() => setEnabledEditRemark(true)}
                              icon={(permissions.includes('edit') || value.branchCode === admin_branch ) && <EditOutlined style={{borderRadius: '50%'}} />}
                              text={value[field.name] || ''}
                            />
                          </Card>
                        )}
                      </div>
                    ) : (
                      <Col span={12}>{value[field.name]}</Col>
                    )}
                  </Row>
                </div>
              ))}
            </Col>
            <Col>
              {value['status'] === 'WAIT_ADMIN_APPROVE' && (
                <div>
                  <ContainerButton center style={{marginTop: '40px'}}>
                    {perAction.includes('approve') && (
                      <ButtonTheme
                        useFor="CUSTOM"
                        title="อนุมัติ"
                        bgcolor={color.success}
                        onClick={() => approveDoc('WAIT_FINANCE_APPROVE')}
                        style={{width: '130px', borderRadius: '30px', boxShadow: '2px 2px 2px #707070'}}
                      />
                    )}
                    {perAction.includes('cancel') && (
                      <ButtonTheme
                        useFor="CUSTOM"
                        title="ไม่อนุมัติ"
                        bgcolor={color.danger}
                        onClick={() => approveDoc('TERMINATE')}
                        style={{width: '130px', marginLeft: '20px', borderRadius: '30px', boxShadow: '2px 2px 2px #707070'}}
                      />
                    )}
                  </ContainerButton>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};
