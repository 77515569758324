import React, {useState, useEffect, useRef} from 'react';
import {Modal, Card} from 'antd';
import {BookOutlined} from '@ant-design/icons';
import Wrapper from '../../../../views/wrapper/Wrapper';
import {POST, POST_FGF_APPROVE_COMMISSION_LIST, POST_FGF_APPROVE_COMMISSION_VERIFY} from '../../../../services/index';
import {PageLayout} from '../../../../components/pageLayout/PageLayout';
import {convertStrToFormat} from '../../../../functions/fn';
import {FGFApproveCommissionVerifyModal} from '../../modal';
import {FGFApproveCommissionSearchForm} from '../../form/FGFApproveCommissionSearchForm';
import {FGFApproveCommissionListColumn} from '../../columns';
import {Spinners} from '../../../../components';

const FGFApproveCommissionListScreen = () => {
  const [loading, setLoading] = useState(true);
  const [visibleVerifyModal, setVisibleVerifyModal] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const pageRef = useRef(1);
  const sizeRef = useRef(10);
  const [totalSize, setTotalSize] = useState(0);
  const [userNameState, setUserNameState] = useState('');
  const [userIdCardState, setUserIdCardState] = useState('');
  const [userTelState, setUserTelState] = useState('');
  const [defaultValue, setDefaultValue] = useState({});
  const [valueSearch, setValueSearch] = useState({});

  useEffect(() => {
    getApproveCommissionList({page: pageRef.current, size: sizeRef.current, name: '', id_card: '', tel: ''});
  }, []);

  const createParams = (value) => {
    const params = [];
    let result = '';

    Object.keys(value).forEach((key) => {
      if (value[key]) {
        params.push(key + '=' + value[key]);
      }
    });

    if (params.length !== 0) {
      result += params.join('&');
    }

    return result;
  };

  const getApproveCommissionList = async (value) => {
    value.page = value?.page || 1;
    value.size = value?.size || 10;

    pageRef.current = value.page;
    sizeRef.current = value.size;

    value.name = value.name || '';
    value.id_card = value.id_card || '';
    value.tel = value.tel || '';

    const params = createParams(value);
    const {name, tel, id_card} = value;

    setLoading(true);
    setUserNameState(name);
    setUserTelState(tel);
    setUserIdCardState(id_card);
    setValueSearch(value);

    try {
      /* FGF: 3.อนุมัติคอมมิชชั่น Get List */
      const res = await POST(POST_FGF_APPROVE_COMMISSION_LIST(params));
      const {success, data} = res;
      const {result, total_item} = data;

      if (success) {
        setTotalSize(total_item);
        const result2 = result.map((el) => {
          const newData = {
            key: el?._id || '',
            branch: `${el?.branchName || ''}`,
            ...el,
          };
          return newData;
        });

        setDataSource(result2);
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onPageSizeChangeCustom = (pageData, sizeData) => {
    const sizeChange = sizeRef.current !== sizeData;
    if (sizeChange) {
      pageRef.current = 1;
    } else {
      pageRef.current = pageData;
    }

    sizeRef.current = sizeData;
    const name = userNameState;
    const idCard = userIdCardState;
    const tel = userTelState;

    const params = {
      page: pageRef.current,
      size: sizeRef.current,
      name: name || '',
      id_card: idCard || '',
      tel: tel || '',
    };

    getApproveCommissionList(params);
  };

  const openModal = (record, name) => {
    if (record) {
      setDefaultValue(record);
      if (name === 'verify') {
        getDataVerify(record);
      }
    }
  };

  const getDataVerify = async (value) => {
    if (!value) {
      Modal.error({
        title: 'ไม่พบข้อมูล',
        content: 'ไม่สามารถดึงรายละเอียดข้อมูลมาแสดงได้',
      });
    } else {
      try {
        setLoading(true);
        /* FGF: 3.อนุมัติคอมมิชชั่น Get Detail */
        const {_id} = value;
        const res = await POST(POST_FGF_APPROVE_COMMISSION_VERIFY(_id));
        const {success, result} = res;

        if (success) {
          const {user_info, bank_info, amount} = result;
          const newResult = {
            full_name: user_info?.name ? user_info.name : null,
            id_card: user_info?.id_card ? user_info.id_card : null,
            tel: user_info?.tel ? user_info.tel : null,
            commission_amount: amount?.commission_amount ? convertStrToFormat(amount?.commission_amount, 'money') : 0,
            charge_amount: amount?.charge_amount ? convertStrToFormat(amount?.charge_amount, 'money') : 0,
            net_amount: amount?.net_amount ? convertStrToFormat(amount?.net_amount, 'money') : 0,
            approve_paid_amount: amount?.net_amount ? convertStrToFormat(amount?.net_amount, 'money') : 0,
            book_bank_no: bank_info?.account_number ? bank_info?.account_number : null,
            book_bank_name: bank_info?.account_name ? bank_info?.account_name : null,
            bank_name: bank_info?.name ? bank_info?.name : null,
            book_bank_branch: bank_info?.branch ? bank_info?.branch : null,
            book_bank_image: user_info?.book_bank_image ? user_info?.book_bank_image : '',
            id_card_image: user_info?.id_card_image ? user_info?.id_card_image : '',
            rename_image: user_info?.rename_image ? user_info?.rename_image : '',
          };

          setDefaultValue(newResult);
          setVisibleVerifyModal(true);
        }
      } catch (err) {
        const {message, error} = err;
        Modal.error({
          title: message,
          content: error || '',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Wrapper>
      {loading && <Spinners />}
      <Card>
        <PageLayout
          searchLayout={{
            title: 'อนุมัติโอนค่าคอมมิชชั่น Friend Get Friend',
            icon: <BookOutlined />,
            spanSearch: 24,
            formSearch: FGFApproveCommissionSearchForm(),
            onSearch: (data) => getApproveCommissionList(data),
          }}
          tableLayout={{
            columns: FGFApproveCommissionListColumn({openModal}),
            dataSource: dataSource,
            rowKey: (record) => record.key,
            pagination: {
              showSizeChanger: true,
              current: pageRef.current,
              pageSize: sizeRef.current,
              onChange: (p, s) => onPageSizeChangeCustom(p, s),
              pageSizeOptions: ['10', '20', '30', '40', '100', '200', '300'],
              total: totalSize || 0,
              showTotal: (totalSize) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
              defaultPageSize: sizeRef.current,
              defaultCurrent: 1,
            },
          }}
        />
      </Card>
      <FGFApproveCommissionVerifyModal
        visible={visibleVerifyModal}
        title={'ตรวจสอบข้อมูล'}
        onClose={() => setVisibleVerifyModal(false)}
        data={{reApi: getApproveCommissionList, record: defaultValue, valueSearch}}
      />
    </Wrapper>
  );
};

export default FGFApproveCommissionListScreen;
