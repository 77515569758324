import React, {useState, useEffect} from 'react';
import {Modal, Col, Form, Row, Input} from 'antd';
import {ContainerButton} from '../../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../../components/buttons';
import {Icon} from '../../../../resources/icon';
import {DatatableLog, Spinners} from '../../../../components';
import {TextSmall} from '../../../../components/forms/text';
import {EditRemark1Column} from './columns';
import {GET, PUT, GET_CONTRACT_REMARK, PUT_EDIT_REMARK} from '../../../../services/index';
import {createParams} from '../../utils/utils';
const {TextArea} = Input;

export const ModalEditRemark = ({visible, onClose, modalData}) => {
  const {contract_no, remarkFieldName, reApi} = modalData;
  const RESPONSIVE_VERTICAL = {xs: 24, md: 24, lg: 24, xl: 24, xxl: 24};
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRemarkData = async () => {
      if (visible) {
        await getRemarkData();
      }
    };

    form.setFieldValue('remark', modalData[remarkFieldName]);
    fetchRemarkData();
  }, [visible]);

  const getRemarkData = async () => {
    try {
      setLoading(true);
      const value = {contract_no: contract_no, fieldName: remarkFieldName};
      const params = createParams(value);
      const res = await GET(GET_CONTRACT_REMARK(params));
      const {success, result} = res;

      if (success) {
        setData(result?.remark_log);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({title: message, content: error || ''});
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const onCloseMain = () => {
    form.resetFields();
    reApi(contract_no);
    onClose();
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);

      const setInput = {
        contract_no: contract_no,
        [remarkFieldName]: values?.remark,
      };
      const res = await PUT(PUT_EDIT_REMARK, setInput);

      const {success, message} = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
          },
        });
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Spinners />}
      <Modal
        title={`แก้ไขหมายเหตุ${remarkFieldName === 'remark' ? '1' : '2'}`}
        open={visible}
        width={1200}
        onCancel={onCloseMain}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        style={{border: 'unset'}}>
        <Form
          form={form}
          scrollToFirstError={true}
          autoComplete="off"
          labelAlign="left"
          layout="vertical"
          onFinish={(value) => {
            Modal.confirm({
              title: 'ยืนยัน',
              icon: <Icon.warningAlert />,
              content: 'ยืนยันการบันทึก',
              onOk() {
                onFinish(value);
              },
              okText: 'ยืนยัน',
              cancelText: 'ยกเลิก',
            });
          }}>
          <Row gutter={[12, 0]}>
            <Col {...RESPONSIVE_VERTICAL}>
              <Form.Item
                label={<TextSmall bold={true} text={`หมายเหตุ${remarkFieldName === 'remark' ? '1' : '2'}`} />}
                name={'remark'}
                rules={[{required: true, message: 'กรุณาระบุ'}]}>
                <TextArea rows={2} placeholder={'กรอกได้สูงสุด 200 ตัวอักษร'} maxLength={200} style={{borderRadius: '8px'}} />
              </Form.Item>
            </Col>
          </Row>

          <ContainerButton right>
            <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading} />
            <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
          </ContainerButton>
        </Form>

        <DatatableLog style={{marginTop: '20px'}} columns={EditRemark1Column()} data={data} pagination={{pageSize: 5}} showDivider={false} />
      </Modal>
    </>
  );
};

export default ModalEditRemark;
