export const EditSocialIdAndTagsHistoryColumn = () => {
  return [
    {
      title: 'วันที่บันทึก',
      dataIndex: 'createdDate',
      key: 'createdDate',
      width: '12%',
      render: (item) => {
        return item;
      },
    },
    {
      title: 'ข้อมูล (ก่อน)',
      dataIndex: 'social_id_before',
      key: 'social_id_before',
      width: '35%',
      render: (text) => {
        const textSplit = text?.split('@');
        return (
          <>
            {textSplit[0] && <div>{textSplit[0]}</div>}
            {textSplit[1] && <div>{textSplit[1]}</div>}
            {textSplit[2] && <div>{textSplit[2]}</div>}
          </>
        );
      },
    },
    {
      title: 'ข้อมูล (หลัง)',
      dataIndex: 'social_id_after',
      key: 'social_id_after',
      width: '35%',
      render: (text) => {
        const textSplit = text?.split('@');
        return (
          <>
            {textSplit[0] && <div>{textSplit[0]}</div>}
            {textSplit[1] && <div>{textSplit[1]}</div>}
            {textSplit[2] && <div>{textSplit[2]}</div>}
          </>
        );
      },
    },
    {
      title: 'บันทึกโดย',
      dataIndex: 'createdName',
      key: 'createdName',
      width: '18%',
    },
  ];
};
