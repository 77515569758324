import React, {useState, useEffect} from 'react';
import {Modal} from 'antd';
import {useForm} from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {EDIT_REMARK_FORM} from '../form';
import {GET, POST, PUT, EDIT_TRACKING_REMARK} from '../../../services';
import {Icon} from '../../../resources/icon';

export const EditRemarkModal = ({title, visible, onClose, data,reApi, filterParams}) => {
  const {
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue('remark', data?.remark);
  }, [data]);

  const openConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: 'ยืนยันการแก้ไขหมายเหตุ',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);
    try {
      const payload={contract_no:data?.contract_no,remark:params?.remark}
      let res = await POST(EDIT_TRACKING_REMARK,payload);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            reApi(filterParams);
            onCloseMain();
          },
        });
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onCloseMain = () => {
    clearErrors();
    setValue('remark', '');
    onClose();
  };
  return (
    <Modal title={title || 'modal'} open={visible} width={480} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(openConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={EDIT_REMARK_FORM()}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={loading}/>
                <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
