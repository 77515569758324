import React from 'react';
import { Divider, Button } from 'antd';
import lodashGet from 'lodash/get';
import { useReceiptPaymentState, useReceiptPaymentDispatch } from '../hooks/useReceiptPayment';
import { InfoContainer, InfoGroup, InfoItem, InfoLabel, InfoValue } from './styled-component';
import * as logic from './logic';

const PayInfoView = () => {
  const { receiptFilterState, receiptSummary } = useReceiptPaymentState();
  const { dispatchGetSummaryInfo, dispatchClearSummaryInfo, dispatchGetSummaryAmount, dispatchClearPaidParams, dispatchClearAmountContract, dispatchClearUpdateRemark, dispatchClearGetCouponDetail } = useReceiptPaymentDispatch();
  const contractInfoState = receiptSummary?.summary_info;

  const onRequestInitCalPaidValue = () => {
    const params = {
      transaction_date: logic.getCurrentDate(true),
    };
    dispatchGetSummaryInfo(receiptFilterState.contractNo, params);
    const isNotFetch = receiptSummary?.summary_amount?.loading || receiptSummary?.summary_amount?.error;
    if (isNotFetch) {
      return;
    }
    const paramsAmount = {
      transaction_date: logic.getDateQueryFormat(),
      paid_amount: 0,
    };
    dispatchGetSummaryAmount(receiptFilterState?.contractNo, paramsAmount, true);
  };

  const onClickRefresh = () => {
    dispatchClearSummaryInfo();
    dispatchClearPaidParams();
    dispatchClearAmountContract();
    dispatchClearUpdateRemark()
    dispatchClearGetCouponDetail()
    onRequestInitCalPaidValue();
  };

  return (
    <InfoContainer>
      <InfoItem>
        <InfoLabel>เลขที่สัญญา : </InfoLabel>
        <InfoValue>{lodashGet(contractInfoState, 'contract.contract_no', '-')}</InfoValue>
        <InfoLabel style={{ marginLeft: '2rem' }}>บริษัท:</InfoLabel>
        <InfoValue>{lodashGet(contractInfoState, 'company', '-')}</InfoValue>
        <Button type="primary" style={{ margin: '0 2rem' }} onClick={onClickRefresh}>
          Refresh ข้อมูลสัญญา
        </Button>
      </InfoItem>
      <Divider />
      <InfoGroup>
        <InfoItem width={500}>
          <InfoLabel>ชื่อลูกค้า : </InfoLabel>
          <InfoValue>{lodashGet(contractInfoState, 'user.general.fullname', '-')}</InfoValue>
        </InfoItem>
        <InfoItem width={500}>
          <InfoLabel>เลขประจำตัวประชาชน : </InfoLabel>
          <InfoValue>{logic.renderThaiId(lodashGet(contractInfoState, 'contract.user_id_card', 1409700136111))}</InfoValue>
        </InfoItem>
      </InfoGroup>
      <InfoGroup>
        <InfoItem width={500}>
          <InfoLabel>หมายเหตุสัญญา : </InfoLabel>
          <InfoValue>{lodashGet(contractInfoState, 'user.remark', '-')}</InfoValue>
        </InfoItem>
      </InfoGroup>
      <InfoGroup>
        <InfoItem width={250}>
          <InfoLabel>งวดค้าง : </InfoLabel>
          <InfoValue>{`${logic.countOverduePeriod(lodashGet(contractInfoState, 'payment', 0))} งวด`}</InfoValue>
        </InfoItem>
        <InfoItem width={250}>
          <InfoLabel>ค่าปรับ (%) : </InfoLabel>
          <InfoValue>{lodashGet(contractInfoState, 'contract.charge_overdue', '-')}</InfoValue>
        </InfoItem>
        <InfoItem width={250}>
          <InfoLabel>ค่าทวงถาม : </InfoLabel>
          <InfoValue>{lodashGet(contractInfoState, 'contract.charge_warning', '-')}</InfoValue>
        </InfoItem>
      </InfoGroup>
      <Divider />
    </InfoContainer>
  );
};

PayInfoView.propTypes = {};

export default PayInfoView;
