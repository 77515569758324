import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { ContainerButton } from '../../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../../components/buttons';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { auth } from '../../../../utils/firebase';
import { useAuthState } from '../../../../utils/hook';
import RenderForm from '../../../../constants/global/RenderForm';

import { FORM_ICLOUD_REGISTER } from '../form/index';
import { POST, UPDATE_STATUS_WAIT_REGISTER, SORT_ICLOUD_TEL } from '../../../../services/index';

export const ICloudRegisterModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
  } = useForm();

  const { user } = useAuthState(auth);
  const [loading, setLoading] = useState(false);
  const [record, setRecord] = useState({});
  const [confirmICloud, setConfirmICloud] = useState(false);
  const [countRandomTeliCloud, setCountRandomTeliCloud] = useState(1)
  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(() => {
    if (JSON.stringify(data.record) != '{}') {
      const dataValue = Object.keys(data.record);
      dataValue.forEach((val) => setValue(val, data.record[val]));
      setRecord(data.record);
    }
  }, [data]);

  const onCloseMain = () => {
    setValue('confirmICloud', false);
    setConfirmICloud(false);
    clearErrors();
    setRecord({});
    onClose('icloudRegister');
  };

  const onChangeCustom = async (value, ref) => {
    setIsFormChanged(value);
    setValue('confirmICloud', value); // อัพเดทค่าของฟิลด์ในฟอร์ม
  };

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยันการบันทึกข้อมูล',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการบันทึกข้อมูล ใช่หรือไม่',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);
    const { passIcloud, telIcloud, passMail, telMail, pin, remark } = params;
    const obj = {
      userLogin: user.email,
      _id: data.record._id,
      passMail,
      telIcloud,
      passIcloud,
      telMail: telMail ? telMail : '',
      pin,
      remark,
      status: 'WAIT_REGISTER'
    };

    try {
      let res = await POST(UPDATE_STATUS_WAIT_REGISTER, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            data.reApi();
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onRandomTeliCloud = async (ref) => {
    if (ref === 'telIcloud') {
      Modal.confirm({
        title: 'ต้องการสุ่มเบอร์ใหม่ ใช่หรือไม่',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          onSubmitRandomTeliCloud(ref);
        },
        okText: 'ตกลง',
        cancelText: 'ยกเลิก',
      });
  };

  const onSubmitRandomTeliCloud = async (ref) => {
    try {
      // let res = await GET(RANDOM_TEL);
      let res = await POST(SORT_ICLOUD_TEL, { num: countRandomTeliCloud });
      const { success, result } = res;
      if (success) {
        setValue('telIcloud', result.tel);
        data.telIcloud = result.tel;
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setCountRandomTeliCloud(countRandomTeliCloud + 1)
      setLoading(false)
    }
  }
  setRecord(data);
  }

  return (
    <Modal title={title || 'modal'} open={visible} onCancel={onCloseMain} width={720} footer={null} destroyOnClose={true} maskClosable={false}>
      <form onSubmit={handleSubmit(onConfirm)}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          formList={FORM_ICLOUD_REGISTER({
            data: { ...record, confirmICloud },
            onChange: onChangeCustom,
            onRandomTeliCloud,
          })}
          justify={'left'}
          renderButton={
            <>
              <ContainerButton right>
                <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" disabled={!isFormChanged} loading={loading} />
              </ContainerButton>
            </>
          }
        />
      </form>
    </Modal>
  );
};
