import React, {useEffect} from 'react';
import {Modal, Col, Form, Row, Input} from 'antd';
import {ContainerButton} from '../../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../../components/buttons';
import {Icon} from '../../../../resources/icon';
import {Spinners} from '../../../../components';
import {TextSmall} from '../../../../components/forms/text';
import {useEditGuest} from './hook';
import {checkIdCardPattern} from '../../../../functions/fn';
import _ from 'lodash';
import {regexNumberOnly, regexThaiOnly} from '../../../../utils/validation';

export const EditGuestModal = ({title, visible, onClose, data, reApi, valueSearch}) => {
  const RESPONSIVE_VERTICAL = {xs: 24, md: 24, lg: 24, xl: 24, xxl: 24};

  const [form] = Form.useForm();

  const onCloseMain = () => {
    form.resetFields();
    reApi(valueSearch);
    onClose();
  };

  const [editGuestFn, editGuestLoading] = useEditGuest();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(data);
    } else {
      form.resetFields();
    }
  }, [visible]);

  const onConfirm = async (values) => {
    const beforeData = {id_card: data?.id_card, tel: data?.tel, fullname: data?.fullname};
    const afterData = {...values};

    const isEqual = _.isEqual(beforeData, afterData);
    let content = 'ยืนยันการบันทึก';

    if (isEqual) {
      content = 'ข้อมูลไม่มีการเปลี่ยนแปลง ต้องการบันทึกหรือไม่?';
    }
    Modal.confirm({
      title: 'ยืนยัน',
      icon: <Icon.warningAlert />,
      content: content,
      onOk() {
        onFinish(values);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onFinish = async (params) => {
    const setInput = {
      id: data?._id,
      ...params,
    };

    const response = await editGuestFn(setInput);

    if (response) {
      Modal.success({
        title: 'บันทึกข้อมูลสำเร็จ',
        afterClose: () => {
          onCloseMain();
        },
      });
    }
  };

  return (
    <>
      {editGuestLoading && <Spinners />}
      <Modal
        title={title || 'modal'}
        open={visible}
        width={640}
        onCancel={onCloseMain}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}
        style={{border: 'unset'}}>
        <Form
          form={form}
          scrollToFirstError={true}
          autoComplete="off"
          labelAlign="right"
          layout="horizontal"
          labelCol={{xs: 6, md: 6, lg: 6, xl: 6, xxl: 6}}
          wrapperCol={{xs: 18, md: 18, lg: 18, xl: 18, xxl: 18}}
          onFinish={onConfirm}>
          <Row gutter={[12, 0]}>
            <Col {...RESPONSIVE_VERTICAL}>
              <Form.Item
                label={<TextSmall bold={true} text={'เลขบัตรประชาชน'} />}
                name={'id_card'}
                rules={[
                  {required: true, message: 'กรุณาระบุ'},
                  {
                    pattern: regexNumberOnly,
                    message: 'กรุณากรอกตัวเลข 0-9',
                  },
                  {
                    validator: (_, value) => {
                      if (checkIdCardPattern(value)) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject();
                      }
                    },
                    message: 'เลขบัตรประชาชนไม่ถูกต้อง',
                  },
                ]}>
                <Input style={{borderRadius: '10px'}} defaultValue={data?.id_card} maxLength={13} />
              </Form.Item>
            </Col>
            <Col {...RESPONSIVE_VERTICAL}>
              <Form.Item
                label={<TextSmall bold={true} text={'ชื่อ - นามสกุล'} />}
                name={'fullname'}
                rules={[
                  {required: true, message: 'กรุณาระบุ'},
                  {
                    pattern: regexThaiOnly,
                    message: 'กรุณากรอกข้อมูลที่ขึ้นต้นด้วยภาษาไทยเท่านั้น',
                  },
                ]}>
                <Input style={{borderRadius: '10px'}} defaultValue={data?.fullname} />
              </Form.Item>
            </Col>
            <Col {...RESPONSIVE_VERTICAL}>
              <Form.Item
                label={<TextSmall bold={true} text={'เบอร์โทรศัพท์'} />}
                name={'tel'}
                rules={[
                  {required: true, message: 'กรุณาระบุ'},
                  {
                    pattern: regexNumberOnly,
                    message: 'กรุณากรอกตัวเลข 0-9',
                  },
                ]}>
                <Input style={{borderRadius: '10px'}} defaultValue={data?.tel} maxLength={10} />
              </Form.Item>
            </Col>
          </Row>

          <ContainerButton right>
            <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" loading={editGuestLoading} />
            <ButtonTheme useFor="CANCEL" onClick={() => onCloseMain()} />
          </ContainerButton>
        </Form>
      </Modal>
    </>
  );
};
