import React from 'react';
import PropTypes from 'prop-types';
import {isPermission} from './utils';

const Permission = ({children, role}) => {
  const menuUser = JSON.parse(localStorage.getItem('menu_user'));

  if (!menuUser) return null;

  return isPermission(menuUser, role.menuCode, role.action) ? <>{children}</> : null;
};

Permission.propTypes = {
  role: PropTypes.shape({
    action: PropTypes.string.isRequired,
    menuCode: PropTypes.string.isRequired,
  }),
};

export default Permission;
