/* eslint-disable default-case */
import React, {useEffect, useRef, useState} from 'react';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {useForm} from 'react-hook-form';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Space, Button, Table, Spin, Row} from 'antd';
import {PlusCircleOutlined} from '@ant-design/icons';
import {
  GET,
  POST,
  GET_BANK_OPTION,
  GET_DATA_OCR,
  GET_OPTION_BRANCH_ADMIN,
  CREATE_APH_NEW_RECORD_SECOND_HAND,
  GET_SECOND_HAND_PRODUCT_OPTION,
} from '../../../services';
import axios from 'axios';
import {checkIdCardPattern, checkSameNameWithPrefix, cutStringImgSigned} from '../../../functions/fn';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import {Icon} from '../../../resources/icon';
import RenderForm from '../../../constants/global/RenderForm';
import {TextLarge} from '../../../components/forms/text';
import {AddNewRecordSecondHandForm} from '../form';
import {auth} from '../../../utils/firebase';
import {useAuthState} from '../../../utils/hook';

const AphAddNewRecordSecondHand = () => {
  const {
    setFocus,
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    control,
    setError,
    clearErrors,
  } = useForm();

  const {user} = useAuthState(auth);

  const admin_branch = localStorage.getItem('admin_branch');
  const [loading, setLoading] = useState(false);
  const [optionBranch, setOptionBranch] = useState([]);
  const [optionProduct, setOptionProduct] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);

  const [request_no, setRequestNo] = useState(null);
  const [source_type, setSource] = useState(null);
  const [branchName, setBranchName] = useState(null);
  const [productName, setProductName] = useState(null);
  const [serialNumber, setSerialNumber] = useState(null);
  const [imeiNumber, setImeiNumber] = useState(null);
  const [conditionProduct, setConditionProduct] = useState(null);
  const [idCard, setIdCard] = useState(null);
  const [name, setName] = useState(null);
  const [tel, setTel] = useState(null);
  const [highestApproveAmount, setHighestApproveAmount] = useState(null);
  const [approvePaidAmount, setApprovePaidAmount] = useState(null);
  const [insuranceStatus, setInsuranceStatus] = useState(null);
  const [insuranceDate, setInsuranceDate] = useState(null);
  const insuranceDateRef = useRef(null);
  const [idCardImageList, setIdCardImageList] = useState([]);
  const [changeNameImageList, setChangeNameImageList] = useState([]);
  const [signatureImage, setSignatureImage] = useState(null);
  const [mobileImageList, setMobileImageList] = useState([]);
  const [documentImageList, setDocumentImageList] = useState([]);
  const [bookBankImage, setBookBankImage] = useState([]);
  const [bookBankBranch, setBookBankBranch] = useState('');
  const [bookBankCode, setBookBankCode] = useState(null);
  const [bookBankName, setBookBankName] = useState('');
  const [bookBankNumber, setBookBankNumber] = useState('');
  const [remark, setRemark] = useState('');
  const [validateIdCardValue, setValidateIdCardValue] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [enabledChangeName, setEnabledChangeName] = useState(false);

  useEffect(async () => {
    await getOptionBranch();
    await getOptionSecndHandProduct();
    await onFetchBankOption();
    setValue('branch', admin_branch);
    setBranchName(admin_branch);
  }, []);

  useEffect(() => {
    if (bookBankImage && bookBankImage[0]?.url) {
      setDisabled(true);
      const spliceUrl = bookBankImage[0]?.url.split('?');
      getDataOCR(spliceUrl[0]);
    }
  }, [bookBankImage]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const getOptionBranch = async () => {  
    try {
      const res = await POST(GET_OPTION_BRANCH_ADMIN, { group: 'APH', branch_code: admin_branch });
      if (res?.success) {
        // const option = res?.result.map((el) => {
        //   if (el.value === 'ON_HOLD') {
        //     el.label = 'ยังไม่ได้ตัดสินใจ';
        //   }

        //   return {
        //     value: el.value,
        //     label: el.label
        //   };
        // });

        const option = res?.result.map((el) => ({
          value: el.value,
          label: el.label
        }));
        
        setOptionBranch(option);
      }
    } catch (err) {
      Modal.error({
        title: err?.message || 'Error',
        content: err?.error || '',
      });
    } finally {
      setLoading(false); // ตั้งค่า loading กลับเป็น `false` เสมอไม่ว่าจะสำเร็จหรือเกิดข้อผิดพลาด
    }
  };

  const getOptionSecndHandProduct = async () => {
    try {
      const res = await GET(GET_SECOND_HAND_PRODUCT_OPTION);
      const {success, result} = res;
      if (success) {
        setOptionProduct(result);
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const onFetchBankOption = async () => {
    try {
      const res = await GET(GET_BANK_OPTION);
      const {message, data} = res;
      if (message === 'get bank options success') {
        const result = data?.bank?.bank.map((el) => {
          return {
            label: el.bankName,
            value: el.bankCode,
          };
        });

        setBankOptions(result);
      }
    } catch (err) {
      const {error} = err;
      Modal.error({
        title: error,
      });
    }
  };

  const handleChange = async (e, ref) => {
    switch (ref) {
      case 'source_type':
        setValue('source_type', e);
        setSource(e);
        break;
      case 'request_no':
        if (e.length <= 12) {
          setValue('request_no', e);
          setRequestNo(e);
        } else {
          setError('request_no', {
            type: 'required',
            message: 'รหัสไม่ควรเกิน 13 ตัวอักษร',
          });
        }
        break;
      case 'branch':
        setValue('branch', e);
        setBranchName(e);
        break;
      case 'product':
        setValue('product', e);
        setProductName(e);
        // if (conditionProduct !== null) {
        //   await fetchSecondHandPriceByCondition(conditionProduct, e);
        // }
        break;
      case 'serial_number':
        setValue('serial_number', e);
        setSerialNumber(e);
        break;
      case 'imei':
        setValue('imei', e);
        setImeiNumber(e);
        break;
      // case 'condition':
      //   setValue('condition', e);
      //   setConditionProduct(e);
      //   await fetchSecondHandPriceByCondition(e, productName);
      //   break;
      // case 'insurance_status':
      //   setValue('insurance_status', e);
      //   setInsuranceStatus(e);
      //   break;
      // case 'insurance_date':
      //   let dateISO = moment(e).toISOString();
      //   insuranceDateRef.current = dateISO;
      //   setValue('insurance_date', e);
      //   setInsuranceDate(e);
      //   break;
      // case 'highest_approve_amount':
      //   setHighestApproveAmount(e);
      //   break;
      case 'approve_paid_amount':
        setValue('approve_paid_amount', e);
        setApprovePaidAmount(e);
        break;
      case 'id_card_image':
        setValue(ref, e.fileList);
        saveImage(e, 'id_card_image');
        clearErrors(ref);
        break;
      case 'id_card':
        setValue('id_card', e);
        if (e.length === 13) {
          const validateIdCard = checkIdCardPattern(e);
          setValidateIdCardValue(validateIdCard);
        }
        setIdCard(e);
        break;
      case 'name':
        let checkName2 = checkSameNameWithPrefix(e, bookBankName);
        if (checkName2 === false) {
          setEnabledChangeName(true);
        } else if (checkName2 === true) {
          setEnabledChangeName(false);
        }
        setValue('name', e);
        setName(e);
        break;
      case 'tel':
        setValue('tel', e);
        setTel(e);
        break;
      case 'book_bank_image':
        setValue(ref, e.fileList);
        saveImage(e, 'book_bank_image');
        clearErrors(ref);
        break;
      case 'change_name_image':
        setValue(ref, e.fileList);
        saveImage(e, 'change_name_image');
        clearErrors(ref);
        break;
      case 'book_bank_code':
        setValue('book_bank_code', e);
        setBookBankCode(e);
        clearErrors(ref);
        clearErrors('book_bank_image');
        break;
      case 'book_bank_branch':
        setValue('book_bank_branch', e);
        setBookBankBranch(e);
        clearErrors(ref);
        clearErrors('book_bank_image');
        break;
      case 'book_bank_name':
        if (name !== null) {
          let checkName = checkSameNameWithPrefix(name, e);
          if (checkName === false) {
            setEnabledChangeName(true);
          } else if (checkName === true) {
            setEnabledChangeName(false);
          }
        }
        setValue('book_bank_name', e);
        clearErrors(ref);
        setBookBankName(e);
        clearErrors('book_bank_image');
        break;
      case 'book_bank_number':
        setValue('book_bank_number', e);
        setBookBankNumber(e);
        clearErrors(ref);
        clearErrors('book_bank_image');
        break;
      // case 'signature_image':
      //     setValue('signature_image', e)
      //     setSignatureImage(e)
      //     break;
      case 'remark':
        setValue('remark', e);
        setRemark(e);
        break;
      case 'mobile_image':
        setValue(ref, e.fileList);
        saveImage(e, 'mobile_image');
        clearErrors(ref);
        break;
      case 'document_image':
        setValue(ref, e.fileList);
        saveImage(e, 'document_image');
        clearErrors(ref);
        break;
    }
    clearErrors(ref);
  };

  const saveImage = (e, ref) => {
    e.fileList.map(async (el, i) => {
      if (el?.response?.message === 'get url upload success') {
        await axios.put(el?.response?.data?.url, e.fileList[i].originFileObj, {headers: {'Content-Type': e.fileList[i].originFileObj.type}});
      }
    });

    switch (ref) {
      case 'id_card_image':
        const objFile = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          }
        });
        setIdCardImageList(objFile);
        break;
      case 'book_bank_image':
        const objFile2 = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          }
        });

        setBookBankImage(objFile2);
        break;
      case 'change_name_image':
        const objFile3 = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          }
        });
        setChangeNameImageList(objFile3);
        break;
      case 'mobile_image':
        const objFile4 = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          }
        });
        setMobileImageList(objFile4);
        break;
      case 'document_image':
        const objFile5 = e.fileList.map((val) => {
          if (val?.response?.message === 'get url upload success') {
            return {
              mimeType: val.response && val.type,
              url: val.response && val?.response?.data?.url,
            };
          }
        });
        setDocumentImageList(objFile5);
        break;
    }
    clearErrors(ref);
  };

  const getDataOCR = async (url) => {
    try {
      const res = await POST(GET_DATA_OCR, {link: url});
      const {message, data} = res;

      if (message === 'get ocr success') {
        if (JSON.stringify(data.ocr) !== '{}') {
          if (data?.ocr?.bankCode !== null) {
            setValue('book_bank_code', data?.ocr.bankCode);
            setBookBankCode(data?.ocr.bankCode);
          }

          if (data?.ocr?.accountName !== null) {
            setValue('book_bank_name', data?.ocr.accountName);
            setBookBankName(data?.ocr.accountName);
            if (name !== null) {
              let checkName = checkSameNameWithPrefix(name, data?.ocr.accountName);
              if (checkName === false) {
                setEnabledChangeName(true);
              } else if (checkName === true) {
                setEnabledChangeName(false);
              }
            } else {
              Modal.error({
                title: 'กรุณากรอกชื่อ-นามสกุล' || '',
              });
            }
          }
          if (data?.ocr?.accountNo !== null) {
            setValue('book_bank_number', data?.ocr.accountNo);
            setBookBankNumber(data?.ocr.accountNo);
          }
          if (data?.ocr.bankCode === null && data?.ocr.accountName && data?.ocr?.accountNo === null) {
            setError('book_bank_image', {type: 'required', message: 'ไม่สามารถอ่าน OCR รูปหน้า Book Bank ได้'});
          }
        } else {
          setError('book_bank_image', {type: 'required', message: 'ไม่สามารถอ่าน OCR รูปหน้า Book Bank ได้'});
        }
        setDisabled(false);
        clearErrors('book_bank_code');
        clearErrors('book_bank_name');
        clearErrors('book_bank_branch');
        clearErrors('book_bank_number');
      }
    } catch (err) {
      const {message, error} = err;
      Modal.error({
        title: message || '',
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  // const fetchSecondHandPriceByCondition = async (conditionValue, productValue) => {
  //   let obj = {
  //     condition: conditionValue,
  //     productName: productValue,
  //   };
  //   try {
  //     const res = await POST(GET_SECOND_HAND_PRICE, obj);
  //     const {success, result} = res;
  //     if (success) {
  //       setValue('highest_approve_amount', result);
  //       setHighestApproveAmount(result);
  //     }
  //   } catch (err) {
  //     const {error} = err;
  //     Modal.error({
  //       title: error,
  //     });
  //   }
  // };

  const onRefresh = () => {
    setLoading(true);
    try {
      setValue('request_no', null);
      setValue('source_type', null);
      setValue('branch', null);
      setValue('product', null);
      setValue('serial_number', null);
      setValue('imei', null);
      // setValue('condition', null);
      // setValue('insurance_status', null);
      setValue('approve_paid_amount', null);
      setValue('id_card_image', []);
      setValue('mobile_image', []);
      setValue('document_image', []);
      setValue('id_card', null);
      setValue('name', null);
      setValue('tel', null);
      setValue('book_bank_image', []);
      setValue('change_name_image', []);
      setValue('book_bank_code', null);
      setValue('book_bank_branch', '');
      setValue('book_bank_name', '');
      setValue('book_bank_number', '');
      // setValue('signature_image', null)
      setValue('remark', '');

      setRequestNo(null)
      setBranchName(null);
      setProductName(null);
      setSerialNumber(null);
      setImeiNumber(null);
      // setConditionProduct(null);
      setIdCard(null);
      setName(null);
      setTel(null);
      // setHighestApproveAmount(null);
      setApprovePaidAmount(null);
      // setInsuranceStatus(null);
      // setInsuranceDate(null);
      // insuranceDateRef.current = null;
      setIdCardImageList([]);
      setChangeNameImageList([]);
      setMobileImageList([]);
      setSignatureImage(null);
      setBookBankImage([]);
      setBookBankBranch('');
      setBookBankCode(null);
      setBookBankName('');
      setBookBankNumber('');
      setRemark('');
      setEnabledChangeName(false);
      clearErrors();
    } catch (err) {
      Modal.error({
        title: err || '',
      });
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  const scrollToError = (e) => {
    window.scrollTo({
      top: e, //100
      left: 100,
      behavior: 'smooth',
    });
  };

  const onConfirm = () => {
    if (branchName === null) {
      setError('branch', {type: 'required', message: 'โปรดเลือกสาขาที่รับซื้อ'});
    }

    if (productName === null) {
      setError('product', {type: 'required', message: 'โปรดเลือกสินค้า'});
    }

    if (serialNumber === null) {
      setError('serial_number', {type: 'required', message: 'กรุณากรอก S/N'});
    }

    if (imeiNumber === null) {
      setError('imei', {type: 'required', message: 'กรุณากรอก IMEI'});
    }

    // if (conditionProduct === null) {
    //   setError('condition', {type: 'required', message: 'โปรดเลือกสภาพเครื่อง'});
    // }

    // if (insuranceStatus === null) {
    //   setError('insurance_status', {type: 'required', message: 'โปรดเลือกประกัน'});
    // }

    // if (insuranceStatus === 'YES') {
    //   if (insuranceDateRef.current === null) {
    //     setError('insurance_date', {type: 'required', message: 'โปรดเลือกวันที่สิ้นสุดประกัน'});
    //   }
    // }

    if (approvePaidAmount === null) {
      setError('approve_paid_amount', {type: 'required', message: 'กรุณากรอกราคารับซื้อ'});
    } else if (approvePaidAmount !== null) {
      // if (parseInt(approvePaidAmount) > highestApproveAmount) {
      //   if (user.email !== allowUserEmail) {
      //     setError('approve_paid_amount', {type: 'required', message: 'ราคารับซื้อต้องไม่เกินกว่าวงเงินสุงสุด'});
      //   }
      // }

      if (!approvePaidAmount.match(/^[0-9]+$/)) {
        setError('approve_paid_amount', {type: 'required', message: 'วงเงินที่กรอกต้องมีเฉพาะตัวเลข'});
      }
    }

    if (JSON.stringify(idCardImageList) === '[]') {
      setError('id_card_image', {type: 'required', message: 'โปรดอัพโหลดรูปคู่บัตรประชาชน'});
      scrollToError(500);
    }

    if (idCard === null) {
      setError('id_card', {type: 'required', message: 'โปรดกรอกเลขบัตรประชาชน'});
    } else if (idCard !== null) {
      if (idCard.length === 13) {
        if (validateIdCardValue === false) {
          setError('id_card', {type: 'required', message: `กรอกเลขบัตรประชาชนให้ถูกต้อง`});
        }
      } else if (idCard.length !== 13) {
        setError('id_card', {type: 'required', message: `ระบุไม่เกินหรือน้อยกว่า 13 ตัวอักษร`});
      }
    }

    if (name === null) {
      setError('name', {type: 'required', message: 'โปรดกรอกชื่อ-นามสกุล'});
    }

    if (tel === null) {
      setError('tel', {type: 'required', message: 'โปรดกรอกเบอร์โทรศัพท์'});
    } else if (tel !== null) {
      if (tel.length !== 10) {
        setError('tel', {type: 'required', message: `ระบุไม่เกินหรือน้อยกว่า 10 ตัวอักษร`});
      }
    }

    if (JSON.stringify(bookBankImage) === '[]') {
      setError('book_bank_image', {type: 'required', message: 'โปรดอัพโหลดรูปหน้าบัญชีธนาคาร'});
      scrollToError(700);
    }

    if (JSON.stringify(mobileImageList) === '[]') {
      setError('mobile_image', {type: 'required', message: 'โปรดอัพโหลดรูปเครื่อง'});
    }

    if (JSON.stringify(documentImageList) === '[]') {
      setError('document_image', {type: 'required', message: 'โปรดอัพโหลดรูปเอกสาร'});
    }

    if (bookBankCode === null) {
      setError('book_bank_code', {type: 'required', message: 'กรุณาเลือกธนาคาร'});
    }

    if (bookBankBranch === '') {
      setError('book_bank_branch', {type: 'required', message: 'กรุณากรอกชื่อสาขาธนาคาร'});
    }

    if (bookBankName === '') {
      setError('book_bank_name', {type: 'required', message: 'กรุณากรอกชื่อบัญชี'});
    }

    if (bookBankNumber === '') {
      setError('book_bank_number', {type: 'required', message: 'กรุณากรอกเลขที่บัญชี'});
    }

    if (enabledChangeName) {
      if (JSON.stringify(changeNameImageList) === '[]') {
        setError('change_name_image', {type: 'required', message: 'โปรดอัพโหลดรูปใบเปลี่ยนชื่อ'});
        scrollToError(1000);
      }
    }

    if (source_type === null) {
      setError('source_type', {type: 'required', message: 'โปรดเลือกช่องทาง'});
    }

    // if (signatureImage === null) {
    //     setError('signature_image', { type: 'required', message: 'โปรดลงลายมือชื่อ' });
    //     scrollToError(1100)
    // }

    if (enabledChangeName) {
      if (
        JSON.stringify(idCardImageList) !== '[]' &&
        JSON.stringify(bookBankImage) !== '[]' &&
        JSON.stringify(changeNameImageList) !== '[]' &&
        bookBankCode !== null &&
        bookBankName !== '' &&
        bookBankBranch !== '' &&
        bookBankNumber !== '' &&
        branchName !== null &&
        productName !== null &&
        serialNumber !== null &&
        imeiNumber !== null &&
        // conditionProduct !== null &&
        idCard !== null &&
        name !== null &&
        tel !== null &&
        approvePaidAmount !== null &&
        // insuranceStatus !== null &&
        // insuranceDateRef.current !== null &&
        validateIdCardValue &&
        JSON.stringify(mobileImageList) !== '[]' &&
        JSON.stringify(documentImageList) !== '[]' &&
        source_type !== null
      ) {
        if (approvePaidAmount.match(/^[0-9]+$/)) {
          // if (
          //   parseInt(approvePaidAmount) <= highestApproveAmount ||
          //   (parseInt(approvePaidAmount) > highestApproveAmount && user.email === allowUserEmail)
          // ) {
          Modal.confirm({
            title: 'ยืนยัน',
            icon: <Icon.warningAlert />,
            content: 'ยืนยันการเพิ่มรายการรับซื้อสินค้ามือสอง',
            onOk() {
              onSubmit();
            },
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
          });
          // }
        }
      }
    } else {
      if (
        JSON.stringify(idCardImageList) !== '[]' &&
        JSON.stringify(bookBankImage) !== '[]' &&
        bookBankCode !== null &&
        bookBankName !== '' &&
        bookBankBranch !== '' &&
        bookBankNumber !== '' &&
        branchName !== null &&
        productName !== null &&
        serialNumber !== null &&
        imeiNumber !== null &&
        // conditionProduct !== null &&
        idCard !== null &&
        name !== null &&
        tel !== null &&
        approvePaidAmount !== null &&
        // insuranceStatus !== null &&
        // insuranceDateRef.current !== null &&
        validateIdCardValue &&
        JSON.stringify(mobileImageList) !== '[]' &&
        JSON.stringify(documentImageList) !== '[]' &&
        source_type !== null
      ) {
        if (approvePaidAmount.match(/^[0-9]+$/)) {
          // if (
          //   parseInt(approvePaidAmount) <= highestApproveAmount ||
          //   (parseInt(approvePaidAmount) > highestApproveAmount && user.email === allowUserEmail)
          // ) {
          Modal.confirm({
            title: 'ยืนยัน',
            icon: <Icon.warningAlert />,
            content: 'ยืนยันการเพิ่มรายการรับซื้อสินค้ามือสอง',
            onOk() {
              onSubmit();
            },
            okText: 'ยืนยัน',
            cancelText: 'ยกเลิก',
          });
          // }
        }
      }
    }
  };

  const onSubmit = async () => {
    clearErrors();
    const bankName = bankOptions.filter((el) => el.value === bookBankCode);
    const mobileImage = mobileImageList.map((el) => {
      return cutStringImgSigned(el?.url) || '';
    });

    const documentImage = documentImageList.map((el) => {
      return cutStringImgSigned(el?.url) || '';
    });

    let obj = {
      branch: branchName?.trim() || '',
      product: productName?.trim() || '',
      serial_number: serialNumber?.trim() || '',
      imei: imeiNumber?.trim() || '',
      approve_paid_amount: parseInt(approvePaidAmount) || 0,
      imageFrontIdCard: cutStringImgSigned(idCardImageList[0]?.url) || '',
      id_card: idCard?.trim(),
      name: name?.trim() || '',
      tel: tel?.trim() || '',
      book_bank_image: cutStringImgSigned(bookBankImage[0]?.url) || '',
      bank_code: bookBankCode?.trim() || '',
      bank_name: bankName[0]?.label?.trim() || '',
      book_bank_branch: bookBankBranch?.trim() || '',
      book_bank_number: bookBankNumber?.trim() || '',
      book_bank_name: bookBankName?.trim() || '',
      change_name_image: cutStringImgSigned(changeNameImageList[0]?.url) || '',
      remark: remark?.trim(),
      imageMobile: mobileImage || [],
      imageDocument: documentImage || [],
      request_no: request_no?.trim() || '',
      source_type: source_type || null
    };

    setLoading(true);

    try {
      const res = await POST(CREATE_APH_NEW_RECORD_SECOND_HAND, obj);
      const {success, message} = res;
      if (success) {
        Modal.success({
          title: message || '',
          afterClose: () => {
            onRefresh();
            window.open('/aph-buy-second-hand-report', '_self');
          },
        });
      }
    } catch (err) {
      const {error, message} = err;
      Modal.error({
        title: error || message || '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      {loading ? (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 300}}>
          <Spin tip="Loading..." spinning={loading}></Spin>
        </div>
      ) : (
        <Card>
          <Spin tip="Loading..." spinning={loading}></Spin>
          <div style={{display: 'flex', alignItems: 'center', color: '#0052BA', marginBottom: 10}}>
            <PlusCircleOutlined />
            &nbsp;
            <TextLarge text={'เพิ่มรายการรับซื้อสินค้ามือสอง'} color={'#0052BA'} />
          </div>
          <PageLayout
            infoLayout={{
              formInfo: [],
              extra: (
                <form>
                  <RenderForm
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    errors={errors}
                    setError={setError}
                    clearErrors={clearErrors}
                    formList={AddNewRecordSecondHandForm({
                      fn: {handleChange},
                      data: {
                        idCardImageList,
                        bookBankImage,
                        changeNameImageList,
                        enabledChangeName,
                        // insuranceStatus,
                        disabled,
                        mobileImageList,
                        documentImageList,
                      },
                      options: {optionBranch, optionProduct, bankOptions /* optionCondition */},
                    })}
                    renderButton={
                      <ContainerButton center>
                        <ButtonTheme useFor="SUBMIT" title="บันทึก" onClick={() => onConfirm()} />
                        <ButtonTheme
                          useFor="CANCEL"
                          onClick={() => {
                            onRefresh();
                            window.location.replace('/aph-buy-second-hand-report');
                          }}
                        />
                      </ContainerButton>
                    }
                  />
                </form>
              ),
            }}
          />
        </Card>
      )}
    </Wrapper>
  );
};

export default AphAddNewRecordSecondHand;
