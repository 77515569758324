import {useState} from 'react';
import axios from 'axios';
import {Modal} from 'antd';
import {API_CHATCONE_SERVICE} from '../../env.js';

export const useGetHistorySocialId = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getHistorySocialId = async (params) => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_CHATCONE_SERVICE}/admin/history-social-id`, {
        params,
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
          'content-type': 'application/json',
        },
      });

      if (response?.data?.success) {
        setData(response?.data?.data);
      }
    } catch (err) {
      setData([])
      let contents = '';
      if (err?.message?.includes('Network Error')) contents = 'ไม่สามารถเชื่อมต่อ Partner Api Service ได้';

      Modal.error({
        title: 'ไม่สามารถดึงข้อมูล ประวัติการส่งแจ้งเตือน ได้',
        content: contents || err?.response?.data?.error?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return [getHistorySocialId, data, loading];
};
