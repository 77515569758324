import PropTypes from 'prop-types';
import {Table as TableAntd} from 'antd';
import {useState, useEffect} from 'react';

const LogStatusTable = ({statusLogs, pagination, onPageChange}) => {
  // Destructure pagination values
  const {limit, page, total, totalPages} = pagination;

  // Manage local state for current page and page size
  const [currentPage, setCurrentPage] = useState(page || 1);
  const [pageSize, setPageSize] = useState(limit || 10);

  // Update local state if pagination prop changes
  useEffect(() => {
    setCurrentPage(page || 1);
    setPageSize(limit || 10);
  }, [page, limit]);

  // Helper functions for converting log data
  const convertDate = (time) => {
    const date = new Date(time);
    date.setUTCHours(date.getUTCHours() + 7);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear() % 100;
    const hours = date.getUTCHours();
    const minutes = ('0' + date.getUTCMinutes()).slice(-2);
    const seconds = date.getUTCSeconds();
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const convertType = (type) => {
    switch (type) {
      case 'LOSTMODE':
        return `Enable Lost Mode`;
      case 'UN_LOSTMODE':
        return `Disable Lost Mode`;
      case 'LOCKAPP':
        return `Enable Lock App`;
      case 'UN_LOCKAPP':
        return `Disable Lock App`;
      default:
        return type;
    }
  };

  const convertStatus = (status) => {
    switch (status) {
      case 'done':
      case 'Completed':
      case 'SUCCESS':
        return `สำเร็จ`;
      case 'Failed':
        return `ไม่สำเร็จ`;
      case 'PROCESS':
        return `กำลังดำเนินการ`;
      case 'pending':
        return `อยู่ใน Queue`;
      default:
        return status;
    }
  };

  // Define columns for the table
  const columns = [
    {
      title: 'วันที่ทำรายการ',
      dataIndex: 'createDate',
      align: 'center',
      render: (text, record) => convertDate(record?.created_date),
    },
    {
      title: 'Action',
      dataIndex: 'type',
      align: 'center',
      render: (text, record) => convertType(record?.type),
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      align: 'center',
      render: (text, record) => convertStatus(record?.status),
    },
  ];

  // Handle page change
  const handlePageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
    if (onPageChange) {
      onPageChange(page, size); // Call parent function to fetch new data
    }
  };

  // Pagination configuration
  const paginationConfig = {
    current: currentPage,
    pageSize: pageSize,
    total: total || statusLogs.length,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '50'],
    onChange: handlePageChange,
    showTotal: (totalPages) => `รายการทั้งหมด ${totalPages} รายการ`,
  };

  return <TableAntd columns={columns} dataSource={statusLogs} pagination={paginationConfig} />;
};

LogStatusTable.propTypes = {
  statusLogs: PropTypes.array,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  onPageChange: PropTypes.func,
};

LogStatusTable.defaultProps = {
  statusLogs: [],
  pagination: {page: 1, limit: 10, total: 0, totalPages: 0},
  onPageChange: () => {},
};

export default LogStatusTable;
