export const regexNumber = ({input, moreThan}) => {
  if (!input) {
    return false;
  }
  if (moreThan) {
    if (parseInt(input, 10) <= parseInt(moreThan, 10)) {
      return false;
    }
  }
  const pattern = /^\d+$/;
  return pattern.test(input);
};

export const regexJuristicId = ({input}) => {
  if (input.length !== 13) {
    return false;
  }
  const pattern = /^\d+$/;
  return pattern.test(input);
};

export const regexEngThaiChar = ({input}) => {
  if (!input) {
    return false;
  }
  const pattern = /^[a-z,ก-๙]+$/;
  return pattern.test(input);
};

export const regexEmail = ({input}) => {
  if (!input) {
    return false;
  }
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(input);
};

export const regexHashtag = ({input}) => {
  if (!input) {
    return false;
  }
  const pattern = /#+([a-zA-Z0-9ก-๙_]+)/gi;
  return pattern.test(input);
};

export const regexName = ({input}) => {
  if (!input) {
    return false;
  }
  const pattern = /^[a-zA-Z ก-๛]+$/;
  return pattern.test(input);
};

export const regexPassword = ({input}) => {
  if (!input) {
    return false;
  }
  // const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{6,20}$/;
  const pattern = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z]{6,20}$/;
  return pattern.test(input);
};

export const regexNotNull = ({input}) => {
  if (!input) {
    return false;
  }
  if (input.trim() === '') {
    return false;
  }
  return true;
};

export const regexURL = ({input}) => {
  if (!input) {
    return false;
  }
  const pattern =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

  return pattern.test(input);
};

export const regexExp = ({input}) => {
  if (!input) {
    return false;
  }
  if (parseInt(input, 10) > 60 || parseInt(input, 10) < 0) {
    return false;
  }
  const pattern = /^\d+$/;
  return pattern.test(input);
};

/* ภาษาไทย และ space */
export const regexThaiOnly = /^[ก-๙][ก-๙\s]*$/;
/* ตัวเลข */
export const regexNumberOnly = /^[0-9]+$/;