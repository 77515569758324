import React, { useState, useEffect, useMemo } from 'react';
import Wrapper from '../../views/wrapper/Wrapper';
import { Modal, Card, Spin } from 'antd';
import { UnlockOutlined } from '@ant-design/icons';
import { PageLayout } from '../../components/pageLayout/PageLayout';
import { UnlockStatusCRM } from '../../components/modal/UnlockiCloud/UnlockStatusCRM';
import { EditRemarkUnlockIcloudModal } from '../../components/modal/UnlockiCloud/EditRemarkUnlockIcloudModal';
import { UnlockIcloudCRMColumn } from '../../constants/global/columnTableForm';
import { UnlockIcloudCRMSearchForm } from '../../constants/global/SearchForm';
import { auth } from '../../utils/firebase';
import { useCurrentState } from '../../utils/hook';
import { GET, GET_UNLOCK_ICLOUD_CRM } from '../../services';
import { convertStrToFormat } from '../../functions/fn';
import _ from 'lodash'

const UnlockIcloudCRM = () => {
  const CONF_PAGE = 1
  const CONF_SIZE = 10
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [visibleStatusModal, setVisibleStatusModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [contractNo, setContractNo] = useState('');
  const { user } = useCurrentState(auth);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(CONF_PAGE);
  const [size, setSize] = useState(CONF_SIZE);
  const [totalSize, setTotalSize] = useState(0);

  const listOptions = useMemo(() => {
    return { filters, page, size };
  }, [filters, page, size]);

  useEffect(() => {
    if(!_.isEmpty(listOptions?.filters)){
      console.log('listOptions: ', listOptions);
      getDataUnlockiCloudCRM(listOptions);
    }
  }, [listOptions]);


  const openModal = (record, name) => {
    if (record) {
      setDefaultValue(record);
      setContractNo(record.contract_no);
      if (name === 'CHANGE_STATUS') {
        setVisibleStatusModal(true);
      } else if (name === 'REMARK_UNLOCK') {
        setVisibleEditModal(true)
      }
    }
  };

  const getDataUnlockiCloudCRM = async (data) => {
    const  { unlock_status, user_name } = data?.filters;
    setLoading(true);
    try {
      const res = await GET(GET_UNLOCK_ICLOUD_CRM(unlock_status, user_name, page, size));
      const { success, result } = res;
      if (success) {
        let sortByClosedContractDate = result?.data.sort((a, b) => new Date(b.closed_contract_date) - new Date(a.closed_contract_date))
        setDataSource(sortByClosedContractDate);
        setTotalSize(result?.totalItem);
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setLoading(false);
    }
  };

  const optionsCRM = [
    { value: 'NO_STATUS', label: 'ไม่ระบุ' },
    { value: 'CONTACT_ADMIN', label: 'ติดต่อเจ้าหน้าที่' },
    { value: 'NEW_CONTRACT', label: 'สร้างสัญญาใหม่' },
    { value: 'CONTRACT_COMPLETED', label: 'ทำสัญญาใหม่เสร็จสิ้น' },
  ];

  const setPagination = ({page, size}) => {
    setPage(page);
    setSize(size);
  };

  const handleSetFilters = (data) => {
    const filtersData = _.mapValues(data, (value) => 
      value === undefined || value === null || value === "" ? "" : value
  );
    setFilters(filtersData);
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'ปลดล็อกรหัส iCloud ฝ่าย CRM',
              icon: <UnlockOutlined />,
              spanSearch: 24,
              formSearch: UnlockIcloudCRMSearchForm({ options: { optionsCRM } }),
              onSearch: (data) => handleSetFilters(data),
            }}
            tableLayout={{
              columns: UnlockIcloudCRMColumn({ options: { optionsCRM }, openModal, user }),
              dataSource: dataSource,
              pagination: {
                showSizeChanger: true,
                current: page,
                pageSize: size,
                onChange: (p, s) => setPagination({page: p, size: s}),
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                total: totalSize || 0,
                showTotal: (totalSize, range) => `รายการทั้งหมด ${convertStrToFormat(totalSize, 'number-comma')} รายการ`,
                defaultPageSize: size,
                defaultCurrent: 1,
              },
            }}
          />
        </Spin>
      </Card>
      <UnlockStatusCRM
        visible={visibleStatusModal}
        title={'เปลี่ยนสถานะของลูกค้า'}
        onClose={() => setVisibleStatusModal(false)}
        data={{ reApi: ()=> getDataUnlockiCloudCRM(listOptions), record: defaultValue, user }}
      />
      <EditRemarkUnlockIcloudModal
        visible={visibleEditModal}
        title={'แก้ไขหมายเหตุ'}
        onClose={() => setVisibleEditModal(false)}
        data={{ reApi: ()=> getDataUnlockiCloudCRM(listOptions), record: defaultValue, contractNo }}
      />
    </Wrapper>
  );
};

export default UnlockIcloudCRM;
