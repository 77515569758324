import moment from 'moment';
import {createContext, useState, useEffect, useCallback} from 'react';
import {Modal} from 'antd';
import {GET, GET_TRACKING_TASK, GET_TRACKING_STATUS, GET_TERMINATED_CONTRACT_OPTION} from '../../services';
import {convertStatusENtoTH} from '../../constants/status/status';
import {buildArrayOfObjectWithNoDulplicateKey, convertStrToFormat} from '../../functions/fn';
import _ from 'lodash';

const TrackerListContext = createContext({
  isLoading: false,
  data: [],
  dataSource: [],
  dataExport: [],
  optionContractNo: [],
  terminateOption: [],
  paramsUrl: null,
  onSearch: (filter) => {},
});

export const TrackingTaskProvider = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [trackingOptions, setTrackingOptions] = useState([]);
  const [optionContractNo, setOptionContractNo] = useState([]);
  const [terminateOption, setTerminateOption] = useState([]);
  const [paramsUrl, setParamsUrl] = useState(null);
  const [hideTable, setHideTable] = useState(true);

  const dataExportResult = (data) => {
    const result = data.map((item) => {
      const trackerName = item.trackers.map((text) => text.name).join(',');
      return {
        ลำดับที่: item.key,
        เลขที่สัญญา: item.contractNO,
        สถานะสัญญา: convertStatusENtoTH(item.contractStatus, 'contract'),
        ชื่อผู้ติดตาม: trackerName,
        รูปแบบงาน: convertStatusENtoTH(item.status, 'status'),
        ประเภทงาน: item.job_type ? convertStatusENtoTH(item.job_type, 'jobType') + ' ' + item.job_type_remark : '',
        ค่าติดตาม: item.trackingFee,
        ชื่อผู้ทำสัญญา: item.name,
        วันที่รับคำสั่ง: convertStrToFormat(item.trackingTaskCreatedDate, 'date'),
        ระยะเวลา: item.durationAfterTrackingTaskCreated,
        สถานะการติดตาม: convertStatusENtoTH(item.trackingTaskStatus, 'tracking'),
      };
    });
    return result;
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [task, tracking, terminate] = await Promise.all([
        GET(`${GET_TRACKING_TASK}?sortDate=1`),
        GET(GET_TRACKING_STATUS),
        GET(GET_TERMINATED_CONTRACT_OPTION),
      ]);

      setParamsUrl('?sortDate=1');
      if (task.success) {
        task.result.map((item, index) => {
          item.key = index + 1;
          item.job = [item.job_type, item.job_type_remark];
        });
        let opContractArr = [];

        const buildContractNo = buildArrayOfObjectWithNoDulplicateKey('contractNO', task.result);
        buildContractNo.forEach((val) => opContractArr.push({value: val.contractNO, label: val.contractNO}));

        const data = task.result.map((el) => ({...el, terminate_id: el._id, contract_no: el.contractNO}));

        setOptionContractNo(opContractArr);
        setData(task.result);
        setDataSource(data);
        setDataExport(dataExportResult(task.result));
      }
      if (tracking.success) {
        setTrackingOptions(tracking.result);
      }
      if (terminate.success) {
        setTerminateOption(terminate.result);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({title: message});
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    defaultfilter();
  }, []);

  const defaultfilter = async()=> {
    const [tracking, terminate] = await Promise.all([
      GET(GET_TRACKING_STATUS),
      GET(GET_TERMINATED_CONTRACT_OPTION),
    ]);

    if (tracking.success) {
      setTrackingOptions(tracking.result);
    }
    if (terminate.success) {
      setTerminateOption(terminate.result);
    }
  }

  const getFilteredData = async (data) => {

    if( _.isEmpty(data?.contractNO) && _.isEmpty(data?.name) && _.isEmpty(data?.trackers) && _.isEmpty(data?.trackingTaskStatus) && _.isEmpty(data?.type) && _.isEmpty(data?.date)) {
      Modal.error({
        title: 'ไม่สามารถค้นหาข้อมูลได้',
        content: 'จำเป็นต้อง filter อย่างน้อย 1 ฟิลด์ก่อนทำการค้นหา',
      });
    }else {
      setIsLoading(true);
      let params = [];

      try {
        Object.keys(data).forEach((key) => {
          if (data[key]) {
            if (key === 'date') {
              if (data[key][0]) {
                params.push('startTrackingTaskCreatedDate=' + moment(convertStrToFormat(data[key][0], 'timetozero-utc')).format('YYYY-MM-DD'));
              }
              if (data[key][1]) {
                params.push('endTrackingTaskCreatedDate=' + moment(convertStrToFormat(data[key][1], 'timetozero-utc')).format('YYYY-MM-DD'));
              }
            } else {
              params.push(key + '=' + data[key]);
            }
          }
        });

        let url = `${GET_TRACKING_TASK}?sortDate=1&`;
        if (params.length !== 0) {
          url += params.join('&');
          setParamsUrl(`?sortDate=1&${params.join('&')}`);
        }

        let res = await GET(url);
        const {success, result} = res;
        if (success) {
          const resultData = result.map((item, index) => ({
            key: index + 1,
            job: [item.job_type, item.job_type_remark],
            terminate_id: item._id,
            contract_no: item.contractNO,
            ...item,
          }));
          setDataSource(resultData);
          // setDataExport(dataExportResult(resultData));
        }
        setHideTable(false);
      } catch (err) {
        Modal.error({title: err?.message});
      } finally {
        setIsLoading(false);
      }
    }
  };

  const context = {
    isLoading: isLoading,
    data: data,
    dataSource: dataSource,
    dataExport: dataExport,
    trackingOptions: trackingOptions,
    optionContractNo: optionContractNo,
    terminateOption: terminateOption,
    paramsUrl: paramsUrl,
    getFilteredData: getFilteredData,
    hideTable: hideTable
  };

  return <TrackerListContext.Provider value={context}>{props.children}</TrackerListContext.Provider>;
};

export default TrackerListContext;
