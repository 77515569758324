import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Modal, Card, Spin } from 'antd';
import { BookOutlined } from '@ant-design/icons';
import Wrapper from '../../../views/wrapper/Wrapper';
import { PageLayout } from '../../../components/pageLayout/PageLayout';
import { POST, GET_TERMINATED_CONTRACT, EXPORT_TERMINATE_CONTRACT, EXPORT_ACKNOWLEDGMENT_RECEIPT } from '../../../services/index';
import { ContainerButton } from '../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../components/buttons';
import { TERMINATED_SEARCH_FORM } from './form';
import { TerminatedReportColumn } from './columns';
import { optionCondition, optionExportTerminate, optionSendTerminate } from './constant';
import { openPDF } from '../../../functions/fn';
import { useGetExportIssueContractCancel } from '../api';

const TerminatedContractReport = () => {
  const [loading, setLoading] = useState(false);
  const [loadingTerminate, setLoadingTerminate] = useState(false);
  const [loadingAcknowledge, setLoadingAcknowledge] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [valueSearch, setValueSearch] = useState({});
  const [visibleModal, setVisibleModal] = useState(false);
  const [defaultSearch, setDefaultSearch] = useState({
    contract_no: '',
    name: '',
    condition: '',
    terminate_contract_status: '',
    terminate_contract_status_params: '',
    terminate_export_status: '',
    end_date: '',
    start_date: '',
  });
  const [hideTable, setHideTable] = useState(true);


  const [resultReport, loadingReport, errorReport, setInputReport] = useGetExportIssueContractCancel();

  useEffect(() => {
    if (resultReport?.message === 'success' && resultReport?.url) {
      setTimeout(() => {
        window.open(resultReport?.url, '_blank', 'noopener,noreferrer');
        onClose();
      }, 1000);
    }
  }, [resultReport]);

  useEffect(() => {
    if (errorReport) {
      const message = errorReport?.response?.data?.errors;
      Modal.error({ title: 'ดึงข้อมูลไม่สำเร็จ', content: message });
      onClose();
    }
  }, [errorReport]);

  const onExportExcelReport = async () => {
    const params = !_.isEmpty(valueSearch)
      ? Object.entries(valueSearch)
        .filter(([key, value]) => value !== '' && value !== null)
        .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
        .join('&')
      : null;

    params && (await setInputReport(`?${params}`));
  };

  const onGetData = async (params) => {
    if( _.isEmpty(params.contract_no) &&  _.isEmpty(params.name) &&  _.isEmpty(params.condition) &&  _.isEmpty(params.terminate_contract_status) && _.isEmpty(params.terminate_export_status) &&  _.isEmpty(params.end_date) &&  _.isEmpty(params.start_date)) {
      Modal.error({
        title: 'ไม่สามารถค้นหาข้อมูลได้',
        content: 'จำเป็นต้อง filter อย่างน้อย 1 ฟิลด์ก่อนทำการค้นหา',
      });
    } else {
      try {
        setLoading(true);
        const payload = {
          contract_no: params?.contract_no || '',
          name: params?.name || '',
          condition: params?.condition || '',
          terminate_contract_status: params?.terminate_contract_status || '',
          terminate_export_status: params?.terminate_export_status || '',
          end_date: params?.end_date ? moment(params?.end_date).format('YYYY-MM-DD') : '',
          start_date: params?.start_date ? moment(params?.start_date).format('YYYY-MM-DD') : '',
        };
  
        setDefaultSearch({
          contract_no: '',
          name: '',
          condition: '',
          terminate_contract_status: params?.terminate_contract_status !== '' ? params?.terminate_contract_status : '',
          terminate_contract_status_params: params?.terminate_contract_status !== '' ? params?.terminate_contract_status : '',
          terminate_export_status: '',
          end_date: '',
          start_date: '',
        });
        setValueSearch(payload);
  
        const res = await POST(GET_TERMINATED_CONTRACT, payload);
        if (res?.success) {
          setDataSource(res?.data.map((el, index) => ({ ...el, no: index + 1, key: `${el.contract_no}_${index}` })));
          setSelectedRows([]);
          setSelectedRowKeys([]);
        }
        setHideTable(false);
      } catch (error) {
        Modal.error({ title: error?.message });
      } finally {
        setLoading(false);
      }
    }
  };

  const openNewTabPDF = (url) => {
    const win = window.open('', '_blank');
    setTimeout(() => {
      win.document.write(openPDF(url));
    }, 1000);
  };

  const onExportTerminateContract = async () => {
    try {
      setLoadingTerminate(true);

      const payload = { contract_no: selectedRowKeys?.map((item) => item.split('_')[0]) };

      const res = await POST(EXPORT_TERMINATE_CONTRACT, payload);
      if (res?.success) {
        openNewTabPDF(res?.result?.path_file_pdf);
        onGetData(valueSearch);
      }
    } catch (error) {
      Modal.error({ title: error?.message });
    } finally {
      setLoadingTerminate(false);
    }
  };

  const onExportAcknowledgement = async () => {
    try {
      setLoadingAcknowledge(true);

      const payload = { contract_no: selectedRowKeys?.map((item) => item.split('_')[0]) };

      const res = await POST(EXPORT_ACKNOWLEDGMENT_RECEIPT, payload);
      const { success, result } = res;

      if (success) {
        openNewTabPDF(result?.path_file_pdf);
        onGetData(valueSearch);
      }
    } catch (error) {
      Modal.error({ title: error?.message });
    } finally {
      setLoadingAcknowledge(false);
    }
  };

  const onClearCustom = (setValue, formSearch) => {
    formSearch.forEach((val) => setValue(val.name, null));

    setDefaultSearch({
      contract_no: '',
      name: '',
      condition: '',
      terminate_contract_status: null,
      terminate_contract_status_params: null,
      terminate_export_status: '',
      end_date: '',
      start_date: '',
    });
  };

  const onClose = () => setVisibleModal(false);

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading || loadingReport}>
          <PageLayout
            searchLayout={{
              title: 'รายงานการออกหนังสือยกเลิกสัญญา',
              icon: <BookOutlined />,
              spanSearch: 24,
              formSearch: TERMINATED_SEARCH_FORM({
                options: { condition: optionCondition, export: optionExportTerminate, send: optionSendTerminate },
                defaultSearch,
              }),
              dataSearchDefault: defaultSearch,
              onSearch: (data) => onGetData(data),
              onClearCustom,
            }}
            tableLayout={{
              columns: TerminatedReportColumn(),
              dataSource: dataSource,
              hidden: hideTable,
              rowSelection: {
                selectedRowKeys,
                onChange: (selectedKey, selectedRows) => {
                  setSelectedRowKeys(selectedKey);
                  setSelectedRows(selectedRows);
                },
              },
              extraContent: (
                <ContainerButton left>
                  <ButtonTheme
                    useFor="CONTACT"
                    title={'พิมพ์หนังสือยกเลิกสัญญาที่เลือก'}
                    onClick={() => onExportTerminateContract()}
                    loading={loadingTerminate}
                    disabled={selectedRows.length === 0}
                  />
                  <ButtonTheme
                    useFor="CREATE"
                    title={'พิมพ์ใบตอบรับสัญญาที่เลือก'}
                    loading={loadingAcknowledge}
                    onClick={() => onExportAcknowledgement()}
                    disabled={selectedRows.filter((el) => el.contract_status === '-').length > 0 || selectedRows.length === 0}
                  />
                  <ButtonTheme useFor="DOWNLOAD" title={'ดาวน์โหลด Excel'} onClick={() => setVisibleModal(true)} />
                </ContainerButton>
              ),
            }}
          />
        </Spin>
      </Card>
      <Modal
        title={'ต้องการดาวน์โหลด Excel?'}
        open={visibleModal}
        width={550}
        onCancel={onClose}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <ContainerButton right>
          <ButtonTheme useFor="CUSTOM" title={'ตกลง'} onClick={async () => await onExportExcelReport()} />
          <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
        </ContainerButton>
      </Modal>
    </Wrapper>
  );
};

export default TerminatedContractReport;
