import React from 'react';
import { CloseCircleOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Tag, Tooltip, Space } from 'antd';
import { color } from '../../../resources/color';
import { TextSmall } from '../../../components/forms/text';
import { convertStrToFormat } from '../../../functions/fn';
import { ButtonTheme } from '../../../components/buttons';

export const BuySecondHandReportColumn = ({ openModal, user, permissions }) => {
  return [
    {
      title: 'Request No.',
      dataIndex: 'request_no',
      width: '100px',
      align: 'center',
    },
    {
      title: 'เลขที่เอกสาร',
      dataIndex: 'docNo',
      width: '150px',
      align: 'center',
    },
    {
      title: 'วันที่',
      dataIndex: 'date',
      width: '180px',
      align: 'center',
    },
    {
      title: 'สาขา',
      dataIndex: 'branch',
      width: '150px',
      align: 'center',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'tel',
      width: '130px',
      align: 'center',
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      width: '150px',
      align: 'center',
    },
    {
      title: 'ราคารับซื้อ',
      dataIndex: 'approve_paid_amount',
      width: '100px',
      align: 'center',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return (
          <Tag
            style={{ width: '100px' }}
            color={
              record.status === 'WAIT_FINANCE_APPROVE'
                ? color.tranfer
                : record.status === 'WAIT_SIGNATURE'
                  ? color.edit
                  : record.status === 'SUCCESS'
                    ? color.success
                    : record.status === 'WAIT_ADMIN_APPROVE'
                      ? color.tracking
                      : color.disabled
            }>
            {text === 'WAIT_FINANCE_APPROVE'
              ? 'รอการเงินอนุมัติ'
              : text === 'SUCCESS'
                ? 'จ่ายเงินสำเร็จ'
                : text === 'WAIT_SIGNATURE'
                  ? 'รอเซ็นสัญญา'
                  : text === 'WAIT_ADMIN_APPROVE'
                    ? 'รอเจ้าหน้าที่อนุมัติ'
                    : text === 'TERMINATE'
                      ? 'ยกเลิก'
                      : text}
          </Tag>
        );
      },
    },
    {
      title: 'ช่องทาง',
      dataIndex: 'source_type',
      width: '100px',
      align: 'center',
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      // width: '250px',
      align: 'right',
      // fixed:"right",
      render: (text, record) => {
        let cutShowRemark = ''
        if (text.length > 30) {
          cutShowRemark = text.slice(0, 30) + ' ... (อ่านเพิ่มเติม)'
        }
        return (
          <TextSmall
            cursor={'pointer'}
            onClick={() => openModal(record, 'EDIT_REMARK')}
            icon={(permissions.includes('edit') || user?.branch === record.branchCode) ? <EditOutlined style={{ borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px' }} /> : <></>}
            text={cutShowRemark !== '' ? cutShowRemark : text || ''}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'left',
      width: '100px',
      fixed: 'right',
      render: (text, record) => {
        return (
          <>
            <Space>
              {permissions.includes('view') &&
                <Tooltip placement="topLeft" title={'รายละเอียด'} arrowPointAtCenter>
                  <Button
                    style={{ backgroundColor: color.success, color: color.white }}
                    className="ant-btn-customize"
                    icon={<SearchOutlined />}
                    onClick={() => openModal(record, 'DETAIL')}
                  />
                </Tooltip>
              }
              {(permissions.includes('edit') || user?.branch === record.branchCode) && ['WAIT_SIGNATURE', 'WAIT_ADMIN_APPROVE'].includes(record?.status) && (
                <>
                  <Tooltip placement="topLeft" title={'แก้ไข'} arrowPointAtCenter>
                    <Button
                      style={{ backgroundColor: color.edit, color: color.black }}
                      className="ant-btn-customize"
                      icon={<EditOutlined />}
                      onClick={() => window.open(`/aph-edit-record-second-hand?document_no=${record.docNo}`, '_blank')}
                    />
                  </Tooltip>
                </>
              )}
              {permissions.includes('cancel') && ['WAIT_ADMIN_APPROVE', 'WAIT_SIGNATURE'].includes(record?.status) && (
                <>
                  <Tooltip placement="topLeft" title={'ยกเลิก'} arrowPointAtCenter>
                    <Button
                      style={{ backgroundColor: color.danger, color: color.white }}
                      className="ant-btn-customize"
                      icon={<CloseCircleOutlined />}
                      onClick={() => openModal(record, 'CANCEL')}
                    />
                  </Tooltip>
                </>
              )}
            </Space>
          </>
        );
      },
    },
  ];
};

export const SecondHandApproveColumn = ({ openModal }) => {
  return [
    {
      title: 'เลขที่เอกสาร',
      dataIndex: 'docNo',
      width: '40px',
      align: 'center',
    },
    {
      title: 'สาขา',
      dataIndex: 'branch',
      width: '130px',
      align: 'center',
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'userName',
      width: '130px',
      align: 'left',
    },
    {
      title: 'สินค้า',
      dataIndex: 'product',
      width: '150px',
      align: 'center',
    },
    {
      title: 'ราคารับซื้อ',
      dataIndex: 'approve_paid_amount',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return convertStrToFormat(record.approve_paid_amount, 'money');
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '60px',
      align: 'center',
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return (
          <Tag color={record.status === 'WAIT_FINANCE_APPROVE' ? color.tranfer : color.disabled}>
            {text === 'WAIT_FINANCE_APPROVE' ? 'รอการเงินอนุมัติ' : text}
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '180px',
      render: (text, record) => {
        return (
          <>
            <ButtonTheme
              useFor="VERIFY"
              style={{ color: 'black', borderRadius: '5px', width: '130px' }}
              title="ตรวจสอบ"
              onClick={() => openModal(record, 'verify')}
            />
          </>
        );
      },
    },
  ];
};
