// /* eslint no-use-before-define: 0 */
import React, {useState, useEffect, useCallback} from 'react';
import Wrapper from '../../../views/wrapper/Wrapper';
import {Modal, Card, Spin, Row} from 'antd';
import {BookOutlined} from '@ant-design/icons';
import {PageLayout} from '../../../components/pageLayout/PageLayout';
import {GET, POST, GET_MISTAKE_TERMINATE_CONTRACT, EXPORT_MISTAKE_PDF, GET_TRACKERS, GET_JOBTYPE} from '../../../services/index';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {ButtonTheme} from '../../../components/buttons';
import moment from 'moment';
import {MISTAKE_TERMINATED_SEARCH_FORM} from '../form';
import {MistakeTerminateContractColumn} from '../columns';
import {optionCondition} from '../constant';
import {MistakeTerminateContractModal} from '../../../components/modal/Contract/MistakeTerminateContractModal';
import AddTrackingTaskModal from '../../../components/modal/TrackingNo/AddTrackingTaskModal';
import {openPDF} from '../../../functions/fn';
import {BetweenContainer} from '../styled-component';
import {CSVLink} from 'react-csv';
import _ from 'lodash';

const MistakeTerminateContractPage = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExport, setDataExport] = useState([]);
  const [visibleMistakeTerminate, setVisibleMistakeTerminate] = useState(false);
  const [visibleAddTask, setVisibleAddTask] = useState(false);
  const [contractNo, setContractNo] = useState('');
  const [terminateId, setTerminateId] = useState('');
  const [valueSearch, setValueSearch] = useState({});
  const [optionTracker, setOptionTracker] = useState([]);
  const [jobTypeOption, setJobTypeOption] = useState([]);
  const [hideTable, setHideTable] = useState(true);

  useEffect(() => {
    // onGetData({});
    fetchData();
    fetchJobType();
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const res = await GET(GET_TRACKERS);
      const {success, result} = res;
      if (success) {
        const option = result.map((item) => ({
          label: item.name,
          value: item.trackerID,
        }));
        setOptionTracker(option);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  }, []);

  const fetchJobType = useCallback(async () => {
    try {
      const res = await GET(GET_JOBTYPE);
      const {success, result} = res;
      if (success) {
        const option = result.map((item) => ({
          label: item.label,
          value: item.value,
        }));
        setJobTypeOption(option);
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    }
  }, []);

  const onGetData = async (params) => {
    if( _.isEmpty(params?.condition) && _.isEmpty(params?.contract_no) && _.isEmpty(params?.start_date) && _.isEmpty(params?.end_date)) {
      Modal.error({
        title: 'ไม่สามารถค้นหาข้อมูลได้',
        content: 'จำเป็นต้อง filter อย่างน้อย 1 ฟิลด์ก่อนทำการค้นหา',
      });
    }else {
      try {
        setLoading(true);
        const payload = {
          condition: params?.condition || '',
          contract_no: params?.contract_no || '',
          end_date: params?.end_date ? moment(params?.end_date).format('YYYY-MM-DD') : '',
          start_date: params?.start_date ? moment(params?.start_date).format('YYYY-MM-DD') : '',
        };
        setValueSearch(payload);
        const res = await POST(GET_MISTAKE_TERMINATE_CONTRACT, payload);

        const {success, data} = res;
        if (success) {
          setDataSource(
            data.map((el, index) => {
              return {...el, no: index + 1};
            }),
          );
          setDataExport(
            data.map((el, index) => {
              return {
                วันที่ออกหนังสือ: el.terminated_date,
                ประเภทหนังสือยกเลิกสัญญา: el.condition_th,
                เลขที่สัญญา: el.contract_no,
                'ชื่อ-นามสกุล': el.user_name,
                สถานะ: el.status_th,
                ผู้ออกหนังสือ: el.user_create_terminated,
                ภาคสนาม: el.trackingtask_status_th,
              };
            }),
          );
          setLoading(false);
        }
        setHideTable(false);
      } catch (error) {
        Modal.error({title: error?.message});
        setLoading(false);
      }
    }
  };

  const onAddTaskToTracker = (record) => {
    setContractNo(record?.contract_no);
    setTerminateId(record?.terminate_id);
    setVisibleAddTask(true);
  };

  const onCloseAddTaskModal = () => {
    setVisibleAddTask(false);
    onGetData(valueSearch);
  };

  const openNewTabPDF = (url) => {
    const win = window.open('', '_blank');
    setTimeout(() => {
      win.document.write(openPDF(url));
    }, 1000);
  };

  const onExportPdf = async (data) => {
    try {
      const result = data?.pdf_link;
      if (result) {
        openNewTabPDF(result);
      }
    } catch (error) {
      Modal.error({title: error?.message});
    }
  };

  return (
    <Wrapper>
      <Card>
        <Spin tip="Loading..." spinning={loading}>
          <PageLayout
            searchLayout={{
              title: 'การออกหนังสือยกเลิกสัญญาแบบผิดเงื่อนไข',
              icon: <BookOutlined />,
              spanSearch: 24,
              formSearch: MISTAKE_TERMINATED_SEARCH_FORM({options: {condition: optionCondition}}),
              onSearch: (data) => onGetData(data),
            }}
            tableLayout={{
              columns: MistakeTerminateContractColumn(onAddTaskToTracker, onExportPdf),
              dataSource: dataSource,
              hidden: hideTable,
              extraContent: (
                <Row
                  style={{
                    width: '100%',
                  }}
                  align="bottom"
                  justify="space-between">
                  <ButtonTheme useFor="CONTACT" title={'ออกหนังสือยกเลิกสัญญา'} onClick={() => setVisibleMistakeTerminate(true)} />
                  <CSVLink filename={'หนังสือยกเลิกสัญญาแบบผิดเงื่อนไข.csv'} data={dataExport}>
                    <ButtonTheme useFor="DOWNLOAD" title={'Export Excel'} />
                  </CSVLink>
                </Row>
              ),
            }}
          />
        </Spin>
      </Card>
      {visibleMistakeTerminate && (
        <MistakeTerminateContractModal
          visible={visibleMistakeTerminate}
          title={'ออกหนังสือยกเลิกสัญญา'}
          onClose={() => setVisibleMistakeTerminate(false)}
          data={{reApi: onGetData, valueSearch}}
        />
      )}
      {visibleAddTask && (
        <AddTrackingTaskModal
          visible={visibleAddTask}
          onClose={onCloseAddTaskModal}
          data={{contractNO: contractNo, terminateId}}
          options={{optionTracker, jobTypeOption}}
          reApi={onGetData}
          valueSearch={valueSearch}
          >
          </AddTrackingTaskModal>

      )}
    </Wrapper>
  );
};

export default MistakeTerminateContractPage;
