import React, { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'antd';
import { useForm } from 'react-hook-form';
import RenderForm from '../../../../constants/global/RenderForm';
import { ContainerButton } from '../../../../styles/styledComponentGlobal';
import { ButtonTheme } from '../../../../components/buttons';
import { ViewIcloudWaitRegisterForm, ViewIcloudForm } from '../../../../constants/global/actionform';
import { RandomIcloudAccountModal } from '../../../../components/modal/ManageAccount/RandomIcloudAccountModal';
import { POST, UPDATE_STATUS_WAIT_REGISTER, SORT_ICLOUD_TEL } from '../../../../services/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { auth } from '../../../../utils/firebase';
import { useAuthState } from '../../../../utils/hook';

export const ViewIcloudModal = ({ title, visible, onClose, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    clearErrors,
    setError,
  } = useForm();

  const { user } = useAuthState(auth);

  const [record, setRecord] = useState({});
  const [visibleRandom, setVisibleRandom] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [countRandomTeliCloud, setCountRandomTeliCloud] = useState(1);
  const [loading, setLoading] = useState(false);
  const [confirmICloud, setConfirmICloud] = useState(false);

  useEffect(() => {
    if (JSON.stringify(data.record) != '{}') {
      const dataValue = Object.keys(data.record);
      dataValue.forEach((val) => setValue(val, data.record[val]));
      setRecord(data.record);
    }
  }, [data]);

  const onCloseMain = () => {
    clearErrors();
    onClose('view', record);
    setIsFormChanged(false);
    // setConfirmICloud(false)
    // setValue('confirmICloud', false);
    setRecord({});
  };

  const onSetValue = (data) => {
    record.mail = data.mail;
    record.telIcloud = data.telIcloud;
    record.passIcloud = data.passIcloud;
    record.passMail = data.passMail;
    record.pin = data.pin;
    record.status = data?.status;
    record.firstName = data?.firstName;
    record.lastName = data?.lastName;
  };

  // ฟังก์ชันที่ใช้กับ onChange เพื่ออัพเดตสถานะ
  const onchange = (value, ref) => {
    setIsFormChanged(value); // เมื่อเกิดการเปลี่ยนแปลง ให้ตั้งค่าเป็น true
    setValue('confirmICloud', value); // อัพเดทค่าของฟิลด์ในฟอร์ม
  };

  const onRandomTeliCloud = async (ref) => {
    if (ref === 'telIclouds') {
      Modal.confirm({
        title: 'ต้องการสุ่มเบอร์ใหม่ ใช่หรือไม่',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          onSubmitRandomTeliCloud();
        },
        okText: 'ตกลง',
        cancelText: 'ยกเลิก',
      });
    };
    setRecord(data?.record);
  }

  const onSubmitRandomTeliCloud = async () => {
    try {
      let res = await POST(SORT_ICLOUD_TEL, { num: countRandomTeliCloud });
      const { success, result } = res;
      if (success) {
        setValue('telIclouds', result.tel);
        data.telIcloud = result.tel;
      }
    } catch (err) {
      const { message, error } = err;
      Modal.error({
        title: message,
        content: error || '',
      });
    } finally {
      setCountRandomTeliCloud(countRandomTeliCloud + 1)
      setLoading(false)
    }
  }

  const onConfirm = (params) => {
    Modal.confirm({
      title: 'ยืนยันบันทึกข้อมูล',
      icon: <ExclamationCircleOutlined />,
      content: 'ต้องการบันทึกข้อมูล ใช่หรือไม่',
      onOk() {
        onSubmit(params);
      },
      okText: 'ยืนยัน',
      cancelText: 'ยกเลิก',
    });
  };

  const onSubmit = async (params) => {
    setLoading(true);
    const { passIcloud, telIclouds, passMail, telMail, pin, remark, confirmICloud, id } = params;
    const obj = {
      userLogin: user.email,
      _id: id,
      passMail,
      telIcloud: telIclouds,
      passIcloud,
      telMail: telMail ? telMail : '',
      pin,
      remark,
      status: 'WAIT_REGISTER'
    };

    try {
      let res = await POST(UPDATE_STATUS_WAIT_REGISTER, obj);
      const { success, message } = res;
      if (success) {
        Modal.success({
          title: message,
          afterClose: () => {
            onCloseMain();
            data.reApi({});
          },
        });
      }
    } catch (err) {
      const { message } = err;
      Modal.error({
        title: message,
      });
    } finally {
      setLoading(false);
    }
  }

  const getFormList = (onchange, onRandomTeliCloud, record) => {
    if (record?.status === 'WAIT_REGISTER') {
      return ViewIcloudWaitRegisterForm(onchange, onRandomTeliCloud, record);
    } else {
      return ViewIcloudForm(onchange, onRandomTeliCloud, record);
    }
  };

  return (
    <>
      <Modal
        title={title || 'modal'}
        open={visible}
        onClose={visible}
        onCancel={onCloseMain}
        width={720}
        footer={null}
        destroyOnClose={true}
        maskClosable={false}>
        <form onSubmit={handleSubmit(onConfirm)}>
          {/* Render ฟอร์ม */}
          <RenderForm
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            formList={getFormList(onchange, onRandomTeliCloud, record)}
          />

          {/* {renderButtons(record)} */}
          <Row justify={'end'} gutter={4}>
            <Col xs={24} sm={12} md={6} />
            <Col xs={24} sm={12} md={6}>
              <ButtonTheme
                useFor="RANDOM"
                title="สุ่มไอคลาวน์ใหม่"
                onClick={() => setVisibleRandom(true)}
                style={{width: '100%'}} // ให้ปุ่มมีขนาดเท่ากับ Col ที่ครอบ
              />
            </Col>
            {record?.status === 'WAIT_REGISTER' ? (
              <Col xs={24} sm={12} md={6}>
                <ButtonTheme
                  useFor="SUBMIT"
                  htmlType="submit"
                  disabled={!isFormChanged}
                  loading={loading}
                  style={{width: '100%'}} // ให้ปุ่มมีขนาดเท่ากับ Col ที่ครอบ
                />
              </Col>
            ) : null}
            <Col xs={24} sm={12} md={6}>
              <ButtonTheme
                useFor="CANCEL"
                onClick={() => onCloseMain()}
                style={{width: '100%'}} // ให้ปุ่มมีขนาดเท่ากับ Col ที่ครอบ
              />
            </Col>
          </Row>
        </form>
      </Modal>

      {/* Modal สำหรับสุ่มบัญชีไอคลาวน์ใหม่ */}
      <RandomIcloudAccountModal
        visible={visibleRandom}
        title={'สุ่มบัญชีไอคลาวน์ใหม่'}
        onClose={() => setVisibleRandom(false)}
        data={{reApi: data.reApi, record, onSet: onSetValue}}
      />
    </>
  );
};
