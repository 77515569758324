import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from 'antd';
import axios from 'axios';
import { ButtonTheme } from '../../../components/buttons';
import CanvasDraw from 'react-canvas-draw';
import { API_URL } from '../../../env';
import { uploadImg } from '../../../utils/auto-admin/api';
// const { Paragraph } = Typography;

export const ModalSignatureSecondHand = ({ title, visible, onClose, data }) => {
  // const [url, setUrl] = useState(null);
  // const [imageBase64, setImageBase64] = useState(null);
  const { encryptData } = useParams();
  const canvas = useRef(null);

  useEffect(async () => {
    if (JSON.stringify(data.passValue) !== '{}' && data.passValue) {
      // setUrl(data.passValue);

      if (data.passValue.signatureImage === null) {
        // setUrl(null);
      } else if (data.passValue.signatureImage !== null && data.passValue.edit) {
        const image = await fetch(data.passValue?.signatureImage);
        const blob = await image.blob();
        const reader = new FileReader();
        // const base64 = '';
        if (image.status === 200) {
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            // const base64data = reader.result;
            // setImageBase64(base64data);
          };
        }
      }
    }
  }, [data.passValue]);

  // const contentOtherTel = <Paragraph style={{ color: 'white' }}>Click เพื่อให้ลูกค้าเซ็นสัญญารับซื้อสินค้ามือสอง</Paragraph>;

  const onSave = async () => {
    const base64 = canvas.current.getDataURL('png', false, 'white');

    /* ถ้ามี encryptData ให้ call api เส้นใหม่ เพราะใช้ middleware เฉพาะของลูกค้า */
    const imageUrl = encryptData ? await uploadImgByCustomer(base64, 'secondHand_signature') : await uploadImg(base64, 'secondHand_signature');

    let imageUrlCut = imageUrl.split('?');
    // setUrl(imageUrlCut[0]);
    // setImageBase64(base64);

    let imageData = {
      imageUrl: imageUrlCut[0],
      imageBase64: base64,
    }
    data.fn.onGetImageUrl(imageData)
    onClose()
    // data.onChangeCustom(imageUrlCut[0], data.name);
    // setVisibleModal(false);
  };

  const onClear = () => {
    canvas.current.clear();
  };

  //   const onClose = () => {
  //     setVisibleModal(false);
  //   };

  const onCloseMain = () => {
    onClose();
  };

  const uploadImgByCustomer = async (base64, imageCat) => {
    var extension = null;
    base64.replace(/([A-Za-z0-9]+(\/[A-Za-z0-9]+)+)/, (m) => (extension = m));
    let newExtension = extension.replace('image/', ".")

    if (!newExtension) throw new Error('extension not found');

    var qs = new URLSearchParams({ extension: newExtension, imageCat });
    var res = await axios.post(`${API_URL}/admin/aph-secondhand/getUrlUpload?${qs}`, {}, {
      headers: {
        'content-type': extension,
        'Signature': encryptData
      },
    });
    var gcsUrl = res?.data?.data?.url;
    var dataUrl = base64;

    fetch(dataUrl)
      .then(res => res.arrayBuffer())
      .then(buffer => {
        axios.put(gcsUrl, buffer, { headers: { 'Content-Type': extension } });
      })
    return gcsUrl;
  };

  return (
    <>
      <Modal title={title} open={visible} width={1000} onCancel={onCloseMain} footer={null} destroyOnClose={true} maskClosable={false}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CanvasDraw
            ref={canvas}
            brushRadius={2}
            backgroundColor="#f5f5f5"
            hideGrid={true}
            style={{
              width: '100%',
              boxShadow: '0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
          }}>
          <ButtonTheme useFor="CONFIRM" title="บันทึก" onClick={() => onSave()} />
          <ButtonTheme useFor="CLEAR" onClick={() => onClear()} />
        </div>
      </Modal>
    </>
  );
};
