import {Modal, Typography, Row, Spin} from 'antd';
import {useState} from 'react';
import {ButtonTheme} from '../../buttons';
import {useForm} from 'react-hook-form';
import RenderForm from '../../../constants/global/RenderForm';
import {ContainerButton} from '../../../styles/styledComponentGlobal';
import {AddTrackerForm} from '../../../constants/global/actionform';
import {POST, GET_TRACKING_TASK} from '../../../services/index';
import _ from 'lodash';
const {Title} = Typography;

const AddTrackingTaskModal = ({title, visible, onClose, data, options, reApi, valueSearch}) => {
  const [typeOtherJob, setTypeOtherJob] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const {optionTracker, jobTypeOption} = options;

  const {
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
    control,
  } = useForm();

  const reApiFunc = async() => {
    if(!_.isEmpty(reApi)) {
      reApi(valueSearch)
    }
  }

  const onSubmit = async (params) => {
    setIsLoading(true);
    const obj = {
      contractNO: data.contractNO,
      jobType: params.jobType,
      jobTypeRemark: params.jobTypeRemark,
      trackerID: [params.trackerID],
      trackingFee: params.trackingFee,
      terminate_id: data.terminateId || data._id,
    };
    try {
      const res = await POST(GET_TRACKING_TASK, obj);
      const {success} = res;
      if (success) {
        Modal.success({
          title: 'เพิ่มการติดตามสำเร็จ',
          afterClose: () => {
            onClose();
            reApiFunc();
          },
        });
      }
    } catch (err) {
      const {message} = err;
      Modal.error({
        title: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (value) => setTypeOtherJob(value);

  return (
    <Modal
      title={<Title level={3}>{`เพิ่มการติดตาม ${data.contractNO}`}</Title>}
      open={visible}
      onCancel={onClose}
      width={500}
      footer={null}
      destroyOnClose={true}
      maskClosable={false}>
      {isLoading ? (
        <Row width="100%" align="center">
          <Spin />
        </Row>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <RenderForm
            control={control}
            setValue={setValue}
            getValues={getValues}
            errors={errors}
            formList={AddTrackerForm({options: {optionTracker, contractType: jobTypeOption}, onChange, data: {type: typeOtherJob}})}
            renderButton={
              <>
                <ContainerButton right>
                  <ButtonTheme useFor="SUBMIT" title="บันทึก" htmlType="submit" />
                  <ButtonTheme useFor="CANCEL" onClick={() => onClose()} />
                </ContainerButton>
              </>
            }
          />
        </form>
      )}
    </Modal>
  );
};

export default AddTrackingTaskModal;
